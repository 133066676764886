import React, { createContext, useContext, useState } from "react";

// Create Context
const PortfolioContext = createContext();

// Provider Component
export const PortfolioProvider = ({ children }) => {
  const [stocks, setStocks] = useState([]);
  const [currentPrices, setCurrentPrices  ] = useState([]);
  const [currentVolumes , setcurrentVolumes ] = useState([]);
  return (
    <PortfolioContext.Provider
      value={{
        stocks,
        setStocks,
        currentPrices,
        setCurrentPrices,
        currentVolumes,
        setcurrentVolumes ,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

// Custom Hook for consuming context
export const usePortfolio = () => useContext(PortfolioContext);

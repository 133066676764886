
import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';
import { useWindowSize } from "@uidotdev/usehooks";
import { useAuth } from "../../AuthContext"; // Import useAuth
import "./login.css"; // Import specific CSS for Login
import { Base_URL } from "../../Components/BaseURL";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const size = useWindowSize();
  const navigate = useNavigate();

  const { login } = useAuth(); // Use login from context
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [stocks, setStocks] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    // Apply styles on mount
    document.body.style.overflowX = 'hidden';
    document.body.style.overflowY = 'hidden';
  
    // Cleanup on unmount
    return () => {
      document.body.style.overflowX = '';
      document.body.style.overflowY = '';
    };
  }, []);
  useEffect(() => {
    document.title = "stockwiseanalytics.com - LogIn Page"; // Dynamically changes the title
  }, []);

  // Fetch stock data after login
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const username = localStorage.getItem('unm');
        if (username) {
          const res = await axios.get(`${Base_URL}/portfolio/stocks`, {
            params: { username }
          });
          setStocks(res.data);
          const symbols = res.data.map(stock => stock.ctick);
          localStorage.setItem('symbolstring', symbols); // Store symbols for further use
        }
      } catch (error) {
        console.error("Error fetching stock data: ", error);
      }
    };
    // Fetch stock data every second for real-time updates
    const interval = setInterval(() => {
      fetchStockData();
      setCount(count + 1);
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [count,username]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!emailRegex.test(username)) {
        toast.error("Error! You have entered an invalid email.");
        return;
      }
      if (!username || !password) {
        toast.warning("All Fields are Required");
        return;
      }

      const response = await axios.post(`${Base_URL}/auth/login`, {
        username,
        password
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      switch (response.status) {
        case 200:
        case 201:
          const { token, userid, crdate ,fname,lname} = response.data;
          localStorage.setItem('unm', username); 
          localStorage.setItem('token', token); 
          localStorage.setItem('userid', userid); 
          localStorage.setItem('crdate', crdate);
          localStorage.setItem('fname',fname);
          localStorage.setItem('lname',lname);
          login({ token, userid });
          
            if (!fname || fname === "" || !lname || lname === "") {  
              toast.success("LogIn Successful, Redirecting to profile settings page...");
              navigate("/profilesettings");
            }
            else
              {
                toast.success("LogIn Successful, Redirecting to portfolio page...");
                navigate("/portfolio");
              }

        
          break;

        case 401:
          toast.warning("Incorrect Password...");
          break;

        default:
          toast.error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Error Logging User: ", error);
      toast.error("Error Logging User");
    }
  };

  const handleLoginSuccess = (response) => {
    console.log("Google Login Success:", response);
    fetch(`${Base_URL}/auth/google/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential }),
    })
      .then(res => res.json())
      .then(data => {
        localStorage.setItem('unm', data.user.username);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userid', data.user.id);
        localStorage.setItem('crdate', data.user.crdate);
        localStorage.setItem('fname',data.user.fname);
        localStorage.setItem('lname',data.user.lname);
        login({ token: data.token, userid: data.user.id });
            if (!data.user.fname || data.user.fname === "" || !data.user.lname || data.user.lname === "") {  
            toast.success("LogIn Successful, Redirecting to profile settings page...");
            navigate("/profilesettings");
          }
          else
            {
              toast.success("LogIn Successful, Redirecting to portfolio page...");
              navigate("/portfolio");
            }
      })
      .catch(err => {
        console.error('Error during Google authentication:', err);
      });
  };

  const handleLoginError = () => {
    console.error("Google Login Failed");
  };
  function handleClick() {
         navigate("/home");
       }


     const navigateToSigninPage = () => {
    navigate('/userregister');
   };
 
  return (
    <><div className="container-fluid pt-0 mb-0 mt-0 bg-black text-white h-100">
      <div className="mt-0" style={{ marginRight: '0px' }}>
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
            <video
              className="videoTag"
              autoPlay
              loop
              muted
              style={{ width: "60%", height: "100%", }}
            >
              <source src="/videos/login.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-xxl-6 form-container d-flex justify-content-between viewport"
            style={{ paddingRight: '0px' }}>
            <div className="input-group mb-0 flex-column align-items-center min-vh-100"
              style={{
                top: '0px', textAlign: 'center',
                width: '100%',
                background: '#000'
                // background: 'linear-gradient(to bottom right, black, #282727)',background: 'linear-gradient(to bottom, transparent 1%, black 100%)'
              }}>
              <div className='p-3'></div>
              <div className='p-2'></div>
              <div className="col-12 justify-content-center align-items-center">
                <a href="/"><img className="img-fluid d-md-none" style={{ maxWidth: '45px', height: 'auto' }} onClick={handleClick} src="/images/SVAlogo.png" alt="Logo" />
                  <img className="header-logo d-none d-md-block header-logo" onClick={handleClick} src="/images/SVAlogoWithName.png" alt="Logo" />
                </a>
              </div>
              <form onSubmit={handleLogin} className='login-form justify-self' style={{ width: '100%' }}>
                <div className="input-group mb-15">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div class="container mt-1"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="LogIn w-100 text-center signuptitle">Log in</div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div className='mb-3'>
                  <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                    <div style={{ "color": "rgba(255, 255, 255, 0.49)", "textAlign": "left", "fontFamily": "'Montserrat-Regular', sans-serif", "fontSize": "16px", "lineHeight": "31px", "fontWeight": "400", "position": "relative", "alignSelf": "stretch", "flex": "1", "display": "flex", "alignItems": "center", "justifyContent": "flex-start" }}>
                      <input
                        type="email"
                        className="font-family-sans-serif emailctrl"
                        placeholder="Email Address"
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ backgroundcolor: '#000000 !important' }} />
                    </div>
                  </div>
                </div>

                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'>
                  <div style={{ "color": "rgba(255, 255, 255, 0.49)", "textAlign": "left", "fontFamily": "'Montserrat-Regular', sans-serif", "fontSize": "16px", "lineHeight": "31px", "fontWeight": "400", "position": "relative", "alignSelf": "stretch", "flex": "1", "display": "flex", "alignItems": "center", "justifyContent": "flex-start" }}>
                    <div className='row justify-content-center align-items-center d-flex signupp'>
                      <div className="password-wrapper">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="font-family-sans-serif emailctrl"
                          id="password"
                          placeholder="Password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)} /> <div class="toggle-button">
                          <button
                            className='btn'
                            type='button'
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-2 no-border' />
                          </button></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 pt-2">
                  <span className="text-fp text-opacity-50 fs-6 fw-semibold font-family-sans-serif">
                    Forgot Password? <a href="/resetpw" className="textlink-fp">Click here</a>
                  </span>
                </div>


                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex'
                  style={{ "background": "#d9d9d9", "borderRadius": "10px", "borderStyle": "solid", "borderColor": "rgba(255, 255, 255, 0.5)", "borderWidth": "1px", "display": "flex", "flexDirection": "row", "gap": "10px", "alignItems": "center", "justifyContent": "center", "height": "41px", "position": "relative", "overflow": "hidden" }}
                >
                  <div
                    style={{ "color": "#000000", "textAlign": "center", "fontFamily": "'Montserrat-Regular', sans-serif", "fontSize": "16px", "lineHeight": "31px", "fontWeight": "400", "position": "relative", "alignSelf": "stretch", "flex": "1", "display": "flex", "alignItems": "center", "justifyContent": "center" }}
                  >
                    <button type="submit" className="signinbtn" style={{
                      fontFamily: 'sans-serif',
                      whiteSpace: 'nowrap',
                    }}>Log in</button>
                  </div>
                </div>
                {/* <div className='mb-2'>
<div className="form-outline">
        <div className='row justify-content-center align-items-center d-flex pt-3'>
    <hr className="hr-text justify-content-center lign-items-center d-flex w-90" data-content="OR"/>
    </div>
        </div>
    </div> */}
                <div className="form-outline mb-0">
                  <div className="pt-1pb-1  or-text">
                    <span class="line"></span>&nbsp;&nbsp;&nbsp;Or&nbsp;&nbsp;&nbsp;<span class="line"></span>
                  </div>

                </div>

                <div className='mb-3 d-grid gap-2 col-12 mx-auto googlecss justify-content-center align-items-center d-flex  poscss'>
                  <div className="form-outline mb-0 border border-0">
                    <div className='row justify-content-center align-items-center d-flex'>
                      <GoogleLogin onSuccess={handleLoginSuccess} buttonText="Sign up with Google" onError={handleLoginError} />
                    </div>
                  </div>
                </div>


                {/* <div className="input-group mb-3">
      <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
      <FontAwesomeIcon className='btn btn-info' icon={faApple} />
        <button type="submit" className="btn btn-block mt-0 p-0 text-dark">Sign up with Apple</button>
      </div>
    </div> */}
                <div class="p-2"></div>
                {/* <div className="mb-3 ">
    <span className="text-fp text-opacity-50 fs-6 fw-semibold font-family-sans-serif">
    Don’t have an account?&nbsp;<button type="button" onClick={navigateToSigninPage} className="btn btn-block mt-0 p-0 textlink-login">Sign Up</button>
     </span>
   </div> */}

                <div className="mb-3 ">
                  <span className="text-fpn text-opacity-50 fs-6 fw-semibold font-family-sans-serif">
                    Don’t have an account?&nbsp;&nbsp;<button type="button" onClick={navigateToSigninPage} className="btn btn-block mt-0 p-0 textlink-login">Sign Up</button>
                  </span>
                </div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
                <div class="p-2"></div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

    </div><Browser size={size} /></>
  );
}

export default Login;

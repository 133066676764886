// import {
//     AppBar,
//     Box,
//     Container,
//     Grid,
//     IconButton,
//     List,
//     ListItem,
//     ListItemText,
//     Paper,
//     Toolbar,
//     Typography,
// } from "@mui/material";
// import React from "react";
// import { Link } from "react-router-dom";
// import {
//   CssBaseline,
//   ThemeProvider as MuiThemeProvider,
//   createTheme,
// } from "@mui/material";
// import { useWindowSize } from "@uidotdev/usehooks";
// function Browser({ size }) {
//     return (
//       <div
//         data-testid="browser"
//         className="browser"
//         style={{ width: size.width / 4, height: size.height / 4 }}
//       />
//     );
//   }

// function SectionTwo() {
//   const size = useWindowSize();
//   const appTheme = createTheme({
//     palette: {
//         primary: {
//             main: "#121F46",
//         },
//         secondary: {
//             main: "#462912",
//         },
//         error: {
//             main: "#DC1B24",
//         },
//         background: {
//             default: "#000000",
//             paper: "#030407",
//         },
//         text: {
//             primary: "#d3d9d5",
//             secondary: "#ffffff",
//         },
//     },
//     typography: {
//         fontFamily: "sans-serif , Montserrat",
//         h1: {
//             fontSize: "50px",
//             fontWeight: "bold",
//             color: "#d3d9d5",
//         },
//         h6: {
//             fontSize: "1.25rem",
//             fontWeight: "normal",
//             color: "#ffffff",
//         },
//         body1: {
//             fontSize: "1.25rem",
//             fontWeight: "normal",
//             color: "#d3d9d5",
//         },
//         body2: {
//             fontSize: "1rem",
//             fontWeight: "light",
//             color: "#ffffff",
//         },
//     },
//     components: {
//         MuiButton: {
//             styleOverrides: {
//                 root: {
//                     textTransform: "none",
//                 },
//             },
//         },
//         MuiTableCell: {
//             styleOverrides: {
//                 root: ({ theme }) => ({
//                     ...theme.typography.body1,
//                 }),
//                 head: ({ theme }) => ({
//                     ...theme.typography.h6,
//                 }),
//                 body: ({ theme }) => ({
//                     ...theme.typography.body2,
//                 }),
//             },
//         },
//         MuiListItemText: {
//             styleOverrides: {
//                 primary: ({ theme }) => ({
//                     ...theme.typography.body1,
//                 }),
//                 secondary: ({ theme }) => ({
//                     ...theme.typography.body2,
//                 }),
//             },
//         },
//     },
//   });
//     return (
//       <>
//                <Container sx={{ mt: 4, mb: 4 ,mx: { xs: 0, md: 3 },  }}>
//                 <Grid container spacing={16} >
//                     <Grid item xs={12} md={8} >
//                         <Typography
//                             variant="body1"
//                             sx={{
//                                 color: "#d3d9d5",
//                                 fontSize: "1.25rem",
//                                 textAlign: "justify",
//                                 fontFamily: "sans-serif , Montserrat",
//                                 fontWeight: "300",
//                                 // letterSpacing: "0.055rem",
//                                 textIndent: "4.313rem",
//                             }}
//                         >
//                             At SWA, we are proud to be Sri Lanka’s first social trading
//                             platform, designed by traders for traders. Our mission is to
//                             empower investors of all levels to make smarter trading decisions
//                             by connecting them with a community of experienced traders,
//                             providing real-time market data, and delivering actionable
//                             insights into market trends.
//                             <br />
//                             <br />
//                             We believe that trading shouldn’t be a solo journey. That’s why
//                             we’ve built a platform where you can follow expert traders, learn
//                             from their strategies, and even see what they’re buying and
//                             selling. Whether you’re new to investing or a seasoned trader, SWA
//                             gives you the tools and community to take your trading to the next
//                             level.
//                             <br />
//                             <br />
//                             Built with the trader’s needs in mind, we aim to democratize
//                             financial knowledge and create an inclusive space where traders
//                             can grow together, exchange insights, and ultimately succeed. Join
//                             us on this journey and become part of Sri Lanka’s first and only
//                             social trading platform.
//                         </Typography>
//                     </Grid>
//                     {/* <Grid item xs={12} md={4}>
//                         <Box
//                             component="img"
//                             src={"images/about-us/product-img.png"}
//                             sx={{ width: "30.938rem", height: "auto", objectFit: "cover" }}
//                         />
//                     </Grid> */}
                        
                        
//                         <Grid item xs={12} md={4}>
//     <Box
//         component="img"
//         className="img-fluid img-fluid-au d-block d-md-none"
//         src="images/about-us/product-img.png"
//         sx={{
//             display: { xs: "block", md: "none" }, // Show only on small screens
//             width: "100%", // Ensures fluidity
//             height: "auto",
//         }}
//     />
//     <Box
//         component="img"
//         className="img-fluid img-main-fluid-au d-none d-md-block img-responsive"
//         src="images/about-us/product-img.png"
//         sx={{
//             display: { xs: "none", md: "block" }, // Show only on medium and larger screens
//             width: "30.938rem !important ratio ratio-4x3",
//             height: "20.625rem",
//             objectFit: "cover",
//             objectPosition: "center",
//             maxWidth:"100%",

//         }}
//     />
// </Grid>

//                 </Grid>
//             </Container>
//  <Browser size={size} />
//  </>
//     );
// };

// export default SectionTwo;
import { Box, Typography } from "@mui/material";
import * as React from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
      return (
        <div
          data-testid="browser"
          className="browser"
          style={{ width: size.width / 4, height: size.height / 4 }}
        />
      );
    }
function SectionTwo() {
  const size = useWindowSize();
    return (
        <>

<div className="container-fluid p-3 mb-2 mt-1 bg-black text-white">
  <div className="row col-lg-12 pb-2 pt-2" style={{background: 'rgba(255, 255, 255, 0.03)'}} >
    <div className="col d-inline" style={{width:'1.388rem'}}></div>
      

<div className="about-text-sml d-md-none align-items-center px-4  justify-content-center" style={{width:'47.625rem'}}>  
At SWA, we are proud to be Sri Lanka’s first social trading platform,
      designed by traders for traders. Our mission is to empower investors of all
      levels to make smarter trading decisions by connecting them with a community
      of experienced traders, providing real-time market data, and delivering
      actionable insights into market trends.
      <br />
      <br />
      We believe that trading shouldn’t be a solo journey. That’s why we’ve built
      a platform where you can follow expert traders, learn from their strategies,
      and even see what they’re buying and selling. Whether you’re new to
      investing or a seasoned trader, SWA gives you the tools and community to
      take your trading to the next level.
      <br />
      <br />
      Built with the trader’s needs in mind, we aim to democratize financial
      knowledge and create an inclusive space where traders can grow together,
      exchange insights, and ultimately succeed. Join us on this journey and
      become part of Sri Lanka’s first and only social trading platform.
    </div>
    <div className="about-text d-none d-md-block align-items-center justify-content-center" style={{width:'47.625rem'}}>  
At SWA, we are proud to be Sri Lanka’s first social trading platform,
      designed by traders for traders. Our mission is to empower investors of all
      levels to make smarter trading decisions by connecting them with a community
      of experienced traders, providing real-time market data, and delivering
      actionable insights into market trends.
      <br />
      <br />
      We believe that trading shouldn’t be a solo journey. That’s why we’ve built
      a platform where you can follow expert traders, learn from their strategies,
      and even see what they’re buying and selling. Whether you’re new to
      investing or a seasoned trader, SWA gives you the tools and community to
      take your trading to the next level.
      <br />
      <br />
      Built with the trader’s needs in mind, we aim to democratize financial
      knowledge and create an inclusive space where traders can grow together,
      exchange insights, and ultimately succeed. Join us on this journey and
      become part of Sri Lanka’s first and only social trading platform.
    </div>


     
    <div className="col d-inline" style={{width:'10.125rem'}}></div>
    <div className="inline align-items-center justify-content-center" style={{width:'30.938rem'}}>  
    <img className="img-fluid img-fluid-au d-block d-md-none mx-auto" src="images/about-us/product-img.png" />
    <img className="img-fluid img-main-fluid-au d-none d-md-block" style={{width: '30.938rem', height: '20.625rem'}} src="images/about-us/product-img.png"/>
  </div>
  <div className="col d-inline" style={{width:'1.388rem'}}></div>
  </div>
  <div className='p-3' style={{background: 'rgba(255, 255, 255, 0.03)'}}></div>
 
  </div>
  <Browser size={size} />
        </>
    );
}

export default SectionTwo;
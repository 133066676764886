// export default Layout;
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../UI-components/layout/common/header/Header';
import Footer from '../UI-components/layout/common/footer/Footer';
import './Layout.css'; // Import specific CSS for layout

const Layout = () => {
  const location = useLocation();

  return (
    <div className="layout">
      {location.pathname !== '/login' && location.pathname !== '/userregister' &&  location.pathname !== '/forget' && location.pathname !== '/resetpw' && <Header />}
      <main className="content bg-black">
        <Outlet />
      </main>
      {location.pathname !== '/login' && location.pathname !== '/userregister' &&  location.pathname !== '/forget' && location.pathname !== '/resetpw' &&<Footer />}
    </div>
  );
};

export default Layout;
// export default Todayspricevolume;

import React, { useState , useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { usePortfolio } from "../../PortfolioContext";
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
const Todayspricevolume = ({ initialUserData }) => {
  const { stocks, currentPrices , currentVolumes } = usePortfolio();
  useEffect(() => {
    // Apply styles on mount
    document.body.style.overflowX = 'hidden';  
    // Cleanup on unmount
    return () => {
      document.body.style.overflowX = '';
    };
  }, []);
  let loadImage = function(variable) {
   const url_image = `/images/companylogos/${variable}.svg`;
   return (
        <img
            className="rectangle-154 m-1"
            style={{
                borderRadius: '8px',
                flexShrink: 0,
                width: '48px',
                height: '48px',
                position: 'relative',
                objectFit: 'cover',
            }}
            src={url_image}
            alt="Company Logo"
            onError={(e) => { e.target.src = '/images/companylogos/default-logo.png'; }}
        />
    );
};

  return (
    <>
   <div className="container">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
                TODAY’S PRICE & VOLUME INFORMATION
                </p>

<div className="row h-25 scrollevent px-3">
<div className="text-white text-opacity-20 fs-6 fw-normal font-family-sans-serif col-10 m-0" style={{ backgroundColor: 'black', width: '100%', height: '270px', overflow: 'auto'}}> 

            <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-start",
            flexShrink: "0",
            width: "390px",
            position: "relative",
          }}
        >
 <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "325px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
              }}
            >
              Stock{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "150px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
              }}
            >
              Daily Close Price{" "}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "100px",
              position: "relative",
            }}
          >
            <div
              style={{
                color: "#ffffff",
                textAlign: "left",
                fontFamily: "sans-serif,'Montserrat-SemiBold'",
                fontSize: "14px",
                lineHeight: "140%",
                letterSpacing: "-0.01em",
                fontWeight: "600",
                position: "relative",
                width: "100px",
              }}
            >
              Daily Volume{" "}
              </div>
        </div>  
        </div>
        {stocks.map(stock => (

          
        <><div key={stock.ordid}
            style={{
              background: "var(--light-theme-gray-light-grey, #dfe5ef)",
              flexShrink: "0",
              width: "607px",
              height: "1px",
              position: "relative",
            }}
          ></div><div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignItems: "center",
              justifyContent: "flex-start",
              flexShrink: "0",
              width: "607px",
              position: "relative",
            }}
          >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "12px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "325px",
                  position: "relative",
                }}
              >
                {loadImage(stock.ctick)}
                {/* <img
                  className="rectangle-154"
                  style={{
                    borderRadius: "8px",
                    flexShrink: "0",
                    width: "48px",
                    height: "48px",
                    position: "relative",
                    objectFit: "cover",
                  }}
                  src={stock.ctick ? `${localLogoImagePath}/${stock.ctick}.png` : '/images/companylogos/default-logo.png'}></img> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flex: "1",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      color: "#ffffff",
                      textAlign: "left",
                      fontFamily: "sans-serif,'Montserrat-SemiBold'",
                      fontSize: "14px",
                      lineHeight: "140%",
                      letterSpacing: "-0.01em",
                      fontWeight: "600",
                      position: "relative",
                      alignSelf: "stretch",
                    }}
                  >
                    {stock.cname}
                  </div>
                  <div
                    style={{
                      color: "#ffffff",
                      textAlign: "left",
                      fontFamily: "sans-serif,'Montserrat-Regular'",
                      fontSize: "12px",
                      lineHeight: "140%",
                      fontWeight: "400",
                      position: "relative",
                      alignSelf: "stretch",
                    }}
                  >
                    {stock.ctick}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "150px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    color: "#ffffff",
                    textAlign: "left",
                    fontFamily: "sans-serif,'Montserrat-Regular'",
                    fontSize: "14px",
                    lineHeight: "140%",
                    letterSpacing: "-0.02em",
                    fontWeight: "400",
                    position: "relative",
                  }}
                >
                {currentPrices.find((price) => price.symbol === stock.ctick) ? ((currentPrices.find((price) => price.symbol === stock.ctick)).close_prices.cptoday).toFixed(2).toLocaleString() : "N/A"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "0px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexShrink: "0",
                  width: "100px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    color: "#ffffff",
                    textAlign: "left",
                    fontFamily: "sans-serif,'Montserrat-Regular'",
                    fontSize: "14px",
                    lineHeight: "140%",
                    letterSpacing: "-0.02em",
                    fontWeight: "400",
                    position: "relative",
                  }}
                >
              {currentVolumes[stock.ctick] !== undefined ? (currentVolumes[stock.ctick]).toLocaleString() : 'N/A'}
                </div>
              </div>
            </div></>
              ))}
        </div>
      </div>
 </div>
    </>
  );
};

export default Todayspricevolume;

import { Link, useNavigate ,useLocation} from 'react-router-dom';
import Submanagementheader from './submanagementheader';
import Submanagementsecone from './submanagementsecone';

function alsubmanagement() {
    return (
   <>
    <div class="layout">
  <header>
  <Submanagementheader/>
   </header>
  <main class="content">
  <div class="modal-body row">
  <div class="col-md-12">
  <Submanagementsecone/>
  </div>
</div>
  
  </main>

</div> 
</>
    );
}

export default alsubmanagement;
import React from 'react';


function CarouselContent({ CarouselHeader, CarouselContent, CarouselContent1 , CarouselContent2 ,src }) {
    return (
        <>
<div className="container">
<div className="container d-flex align-items-center" style={{ height: '100%' }}>
        <div className="row w-100">
            <div className="col-md-8 d-flex flex-column justify-content-center">
                <h1 className="Carousel_h1 mb-3">{CarouselHeader}</h1>
                <h6 className="Carousel_h6">{CarouselContent}</h6>
                {CarouselContent1 && <h6 className="Carousel_h6">{CarouselContent1}</h6>}
                {CarouselContent2 && <h6 className="Carousel_h6">{CarouselContent2}</h6>}
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
                <img src={src} alt={CarouselHeader} className="Carousel_img" style={{ width: '325px', height: '354px', borderRadius: '20px' }} />
            </div>
        </div>
    </div>
    </div>
    <div className='p-3'></div>
        </>
    );
}

export default CarouselContent;
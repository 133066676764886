import * as React from 'react';
import './subcriptmanagement.css';
import { useNavigate } from "react-router-dom";
function Submanagementsecone() {
  const navigate = useNavigate();
  function navigateTo(path) {
    navigate(path);
  }
  function handleExplore(evt) {
    navigateTo("/");
  }



    return (
        <>
  {/* <div style={{ backgroundColor: "#ffffff15" }}>
   <div className="row text-black justify-content-center align-items-center p-4">
   <div className="card text-center col-lg-2 col-md-4 m-3 col-6" style={{ backgroundColor: '#ffffff' }}>
                    
     <div className="card-body">
    <div class="free2">Free</div>
    <div className="lkr-0">LKR 0</div>
    <div className="uldiv">
      <ul className="uldiv-li">
        <li>Access to Basic Content</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
                            </div>
                        </div>
                        <div className="card text-center  col-lg-2 col-md-4 m-3 col-6" style={{ backgroundColor: '#FFFFFF' }}>
                     
                            <div className="card-body">
                                <h5 className="cardtitle">Premium</h5>
                                <h5 className="cardtitlevalue">LKR 8,000/ month</h5>
                            </div>
                        </div>

                        <div className="card text-center  col-lg-2 col-md-4 col-6  m-3" style={{ backgroundColor: '#ffffff' }}>
                              <div className="card-body">
                                <h5 className="cardtitle">Standard</h5>
                                <h5 className="cardtitlevalue">LKR 5,000/ month</h5>
                            </div>
                        </div>
             
                    </div>
  
            </div> */}

            {/* <div class="section-2">
    <div class="rectangle-199"></div>
    <div class="free2">Free</div>
    <div class="lkr-0">LKR 0</div>
    <div class="uldiv">
      <ul class="uldiv-span">
        <li>Access to Basic Content</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
    <div class="rectangle-25"></div>
    <div class="existing-plan">Existing Plan</div>
    <div class="rectangle-1992"></div>
    <div class="premium2">Premium</div>
    <div class="lkr-8-000-month">
      <span>
        <span class="lkr-8-000-month-span">LKR 8,000/</span>
        <span class="lkr-8-000-month-span2">month</span>
      </span>
    </div>
    <div class="uldiv2">
      <ul class="uldiv2-span">
        <li>Access to Basic Content</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
    <div class="rectangle-252"></div>
    <div class="try-it-now">Try it now</div>
    <div class="rectangle-1993"></div>
    <div class="standard">Standard</div>
    <div class="lkr-5-000-month">
      <span>
        <span class="lkr-5-000-month-span">LKR 5,000/</span>
        <span class="lkr-5-000-month-span2">month</span>
      </span>
    </div>
    <div class="uldiv3">
      <ul class="uldiv3-span">
        <li>Access to Basic Content</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
    <div class="rectangle-253"></div>
    <div class="try-it-now2">Try it now</div>
  </div>
  <img class="rectangle-2" src="rectangle-20.png" />
  <div class="rectangle-3"></div> */}
  {/* <ProfileDetails class="profile-details-instance"></ProfileDetails> */}
  {/* <div class="group-12">
    <div class="subscription-management2">Subscription Management</div>
  </div> */}
  {/* <HeaderAfterLogin class="header-after-login-instance"></HeaderAfterLogin> */}
  {/* <div class="upgrade-your-subscribed-package-and-experience-premium-features">
    <span>
      <span
        class="upgrade-your-subscribed-package-and-experience-premium-features-span"
      >
        Upgrade
      </span>
      <span
        class="upgrade-your-subscribed-package-and-experience-premium-features-span2"
      >
        your subscribed package and
      </span>
      <span
        class="upgrade-your-subscribed-package-and-experience-premium-features-span3"
      >
        experience premium features.
      </span>
    </span>
  </div> */}


<div class="container mt-5 mb-3">
    <div class="row">
      
              <div class="col-md-4 h-50">
            <div class="card p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                     </div>
            
                </div>
                <div className="mt-5  bg-ash">
                <div class="free2">Free</div>
                <div class="mt-5"></div>
                    <div class="lkr-0">LKR 0</div>
                    <div class="mt-5"></div>
                    <div class="mt-5"></div>
    <div class="uldiv">
      <ul class="uldiv-span">
        <li>Access to Basic Content&nbsp;&nbsp;&nbsp;</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
            </div>
        <div className="p-2"></div>
          <div className="col text-center">
            <button type="button" onClick={handleExplore} className="btn bg-black text-white">Existing Plan</button>
          </div>

            <div class="d-flex align-items-center">  
            
            </div>
            </div>

        </div>
        
        <div class="col-md-4 h-50">
            <div class="card p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                     </div>
            
                </div>
                <div className="mt-5  bg-ash">
                <div class="free2">Premium</div>
                <div class="mt-5"></div>
                    <div class="lkr-0">LKR 8,000/ month</div>
                    <div class="mt-5"></div>
                    <div class="mt-5"></div>
    <div class="uldiv">
      <ul class="uldiv-span">
        <li>Access to Basic Content&nbsp;&nbsp;&nbsp;</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
            </div>
        <div className="p-2"></div>
          <div className="col text-center">
            <button type="button" onClick={handleExplore} className="btn bg-black text-white">Try it now</button>
          </div>

            <div class="d-flex align-items-center">  
            
            </div>
            </div>

        </div>
        <div class="col-md-4 h-50">
            <div class="card p-3 mb-2">
                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-row align-items-center">
                     </div>
            
                </div>
                <div className="mt-5  bg-ash">
                <div class="free2">Standard</div>
                <div class="mt-5"></div>
                    <div class="lkr-0">LKR 5,000/ month</div>
                    <div class="mt-5"></div>
                    <div class="mt-5"></div>
    <div class="uldiv">
      <ul class="uldiv-span">
        <li>Access to Basic Content&nbsp;&nbsp;&nbsp;</li>
        <li>Alert creation limited to 3.</li>
      </ul>
    </div>
            </div>
        <div className="p-2"></div>
          <div className="col text-center">
            <button type="button" onClick={handleExplore} className="btn bg-black text-white">Try it now</button>
          </div>

            <div class="d-flex align-items-center">  
            
            </div>
            </div>

        </div>
    </div>
</div>



        </>
    );
}

export default Submanagementsecone;
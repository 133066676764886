import React from 'react';
import './ProfileSettings.css';
import ProfileSettingsHeading from './ProfileSettingsHeading';
import ProfileSettingsPrivate from './ProfileSettingsPrivate';
import ProfileSettingsPublic from './ProfileSettingsPublic';
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function ProfileSettings() {
  const size = useWindowSize();
    return (
        <>
  <div className="container">
  <div className="p-5"></div>
  <header>
  <ProfileSettingsHeading /> 
   </header>
  {/* <main className="content"> */}
  <div className="row">
  <div className="col-md-6">
  <ProfileSettingsPrivate/>
  </div>
  <div className="col-md-6">
  <ProfileSettingsPublic/>
  </div>
</div>
  {/* </main> */}
</div>
<Browser size={size} />
        </>
  
    );
}

export default ProfileSettings;
// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField
} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SendOTPDialogPage from './SendOTPDialogPage'; 
const EditDialog = ({ open, onClose, stockData, onUpdate }) => {
  const [tphone, setTphone] = useState('');
  const [teml, setEml] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [formDataStocks, setFormDataStocks] = useState({
    InputEmail:"",
    InputPWD: "",
    InputMobile:"",
  });
  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState();
  const [rgvalue, setRGValue] = useState('phone');
  // const [showCodeInput, setShowCodeInput] = useState(false);
  const [showSendOTPDialog, setShowSendOTPDialog] = useState(false);
  useEffect(() => {
    setFormDataStocks(prevData => ({
      ...prevData,
    }));
  }, [formDataStocks.InputQty, formDataStocks.InputNewQty]);

  useEffect(() => {
    if (stockData) {
      setFormDataStocks({
        InputEmail:stockData.InputEmail,
        InputPWD: stockData.InputPassword,
        InputMobile: stockData.InputPhone,
      });
      // const tp = stockData.InputPhone;
      // const tempbobileno = '+94**'+tp.replace(/(?<!\()\d(?!\d?$)/g, '*');
      // setTphone(tempbobileno);
      // const temail = stockData.InputEmail;
      // var email = temail;
      // let hide = email.split("@")[0].length - 2;
      // var r = new RegExp(".{"+hide+"}@", "g");
      // email = email.replace(r, "***@");
      // setEml(email);
      // const tp = stockData.InputPhone;
      // if (tp) {
      //     const tempbobileno = '+94**' + tp.replace(/(?<!\()\d(?!\d?$)/g, '*');
      //     setTphone(tempbobileno);
      // } else {
      //     // Handle the case where tp is null
      //     //console.error('InputPhone is null or undefined');
      //     // You can set a default value or handle the error as needed
      //     setTphone(null); // or any default value
      // }
      const temail = stockData.InputEmail;
      if (temail) {
        var email = temail;
        let hide = email.split("@")[0].length - 2;
        var r = new RegExp(".{"+hide+"}@", "g");
        email = email.replace(r, "***@");
        setEml(email);
    } else {
        // Handle the case where tp is null
        //console.error('Email is null or undefined');
        // You can set a default value or handle the error as needed
        setEml(null); // or any default value
    }
    }
  }, [stockData]);

  const handleChangeRG = (event) => {
    setRGValue(event.target.value);
  };
   const handleSendCode = async () => {
    try {
//contact:rgvalue
      const res = await axios.post(Base_URL + `/apiRouter/requpdusername`, { email : formDataStocks.InputEmail ,contact:rgvalue});
      onClose();
       setShowSendOTPDialog(true); // Show SendOTPDialog

    } catch (error) {
      toast.error("Error sending confirmation code. Please try again later.");
      console.error(error);
      return;
    }
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Change Email</Typography>
        <div className="col-auto font-family-sans-serif text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
      <ToastContainer/>
            <form className="form-inline">
              <input type="hidden" name="InputEmail" id="InputEmail" value={formDataStocks.InputEmail}></input>
              <input type="hidden" name="InputPhone" id="InputPhone" value={formDataStocks.InputPhone}></input>
              <div className="row g-3 align-items-center">
                <div className="form-group row">
                  <div className="col-12">
                    <p className="text-black fs-6 fw-normal font-family-sans-serif">We need to send the confirmation code to change your email. Enter the code on the next screen to complete the update.</p>
                  </div>
                  <div className="col-12">
                    <p className="text-black fs-6 fw-normal font-family-sans-serif">How would you like to receive the confirmation code?</p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6">
                    <RadioGroup aria-label="cmode" name="cmode" value={rgvalue} onChange={handleChangeRG}>
                      {/* <FormControlLabel value="phone" control={<Radio />} label={tphone} /> */}
                      <FormControlLabel value="mail" control={<Radio />} label={teml} />
                    </RadioGroup>
                  </div>
                </div>
              </div>
              <div className="p-4"></div>
              <div className="col-auto font-family-sans-serif text-end">
                <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>&nbsp; &nbsp;
                <Button variant="contained" color="primary" onClick={handleSendCode}>Send code</Button>
              </div>
            </form>
      </DialogContent>
    </Dialog>
    <SendOTPDialogPage
        open={showSendOTPDialog}
        onClose={() => setShowSendOTPDialog(false)} // Close the dialog
        stockData={stockData}
        onUpdate={onUpdate} // Pass any required props
      />
              </>
  );
};

export default EditDialog;

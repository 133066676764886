// export default Linechart;
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { chartColors } from './colors.js';
import { useWindowSize } from "@uidotdev/usehooks";
import { usePortfolio } from "../../PortfolioContext";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
const Linechart = ({ initialUserData }) => {
  const size = useWindowSize();
  const { stocks, currentPrices ,currentVolumes } = usePortfolio();
 const labels = ["3 Months ago", "Month ago", "Week ago", "Today"];
 const getDataPoints = (label, stock, matchingData) => {
    const quantity = stock.csemco_quantity;
    const avgPrice = stock.avgprice;

    switch (label) {
      case "3 Months ago":
         return ((parseFloat(matchingData?.close_prices?.cp90days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Month ago":
         return ((parseFloat(matchingData?.close_prices?.cp30days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Week ago":
         return ((parseFloat(matchingData?.close_prices?.cp7days) - parseFloat(avgPrice)) * quantity).toFixed(2);
      case "Today":
         return ((parseFloat(matchingData?.close_prices?.cptoday) - parseFloat(avgPrice)) * quantity).toFixed(2);
      default:
        return 0;
    }
  };

  const data = labels.map((label) => {
    const dataPoint = { label };
    stocks.forEach(stock => {
      const matchingData = currentPrices.find(item => item.symbol === stock.ctick);
      if (matchingData) {
        dataPoint[stock.ctick] = getDataPoints(label, stock, matchingData);
      }
    });
    return dataPoint;
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const sortedPayload = [...payload].sort((a, b) => b.value - a.value);
      return (
        <>        
           <div className="custom-tooltip" style={{backgroundColor: "#fff", width:'150px',border: "1px solid #ccc" }}>
        <p className="label" style={{color: "#000000",fontFamily: 'sans-serif',fontWeight: "bold" }}>{label}</p>
        {sortedPayload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex'}}>
            <span style={{ color: entry.color, fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>{entry.name}: </span>
            <span style={{float:'right', color: "#000000", fontWeight: "bold", fontFamily: 'sans-serif', fontSize: '0.65rem' }}>
                 {new Intl.NumberFormat('en-US', {
                   style: 'decimal',
                   maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(entry.value)}
               </span>
          </div>
        ))}
      </div>
      </>
      );
    }
    return null;
  };

   
  return (
    <>
<div className="container">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
   Gain (LKR)</p>
     <ResponsiveContainer width="100%" height={300}>
      {/* aspect={3} */}
      <LineChart data={data}>
         <CartesianGrid strokeDasharray="3 3" opacity={0.14} />
       <XAxis dataKey="label" interval={0} angle={0} padding={{ left: 30, right: 30 }} textAnchor="middle" dx={-5} dy={5}
          label={{
            fontFamily: 'sans-serif',
            fontSize: '0.65rem',
          }} />
 <YAxis
  padding={{ top: 28 }}
  interval={0}
  tickSize={2}
  width={100}  
  scale="auto"
  domain={["auto", "auto"]}
 // domain={[-1000, 'dataMax + 100']} // Example range; adjust based on your data
  tickFormatter={(value) => 
    //new Intl.NumberFormat().format(value)}
      new Intl.NumberFormat('en-US', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(value)}
  label={{
    value: 'LKR',
    
    position: { x: 40, y: 18 },
    className: 'chart__label',
    fontFamily: 'sans-serif',
    fontSize: '0.60rem',
  }}
/>
        <Tooltip content={<CustomTooltip />}/>
         <Legend layout="horizontal" verticalAlign="top" align="center" wrapperStyle={{ fontFamily: 'sans-serif',fontSize: '0.65rem'}} />
         {stocks.map((stock, index) => (
           <Line
             key={stock.ctick}
             dataKey={stock.ctick}
             name={stock.ctick}
             type="monotone"
             stroke={chartColors[index % chartColors.length]}
             dot={false} // Disable dots on the line
           />
         ))}
       </LineChart>
     </ResponsiveContainer>
     </div>
     <Browser size={size} />
    </>
  );
};

export default Linechart;
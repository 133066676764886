import React, {  useEffect } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      // style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function SectionOne() {
  const size = useWindowSize();
    useEffect(() => {
        // Apply styles on mount
        document.body.style.overflowX = 'hidden';      
        // Cleanup on unmount
        return () => {
          document.body.style.overflowX = '';
        };
      }, []);
    return (
        <>
         <div className="container-fluid p-3 mb-2 mt-1 bg-black text-white">
<div className="main-banner">
        <video autoPlay loop muted webkit-playsinline id="bg-video" className="px-0">
            <source src="/videos/aboutUs.mp4" type="video/mp4" />
        </video>

        <div className="video-overlay header-text">
            <div className="overlay-text">
                <h1>About Us</h1>              
            </div>
        </div>
    </div>
    </div>

 <Browser size={size} />
        </>
    );
}

export default SectionOne;
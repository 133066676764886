// components/Footer.js
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css'
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height:  size.height / 4}}
    />
  );
}
const Footer = () => {
  const size = useWindowSize();
    return (       
      <>

  <footer className="p-0 m-0 pb-0">
  <div className="container-fluid bg-black text-white px-4">
  <div classname="p-3">&nbsp;</div>
    <div className="row">
    <div className="col-lg-1 col-sm-12 col-xs-12"></div>
      <div className="col-lg-3 col-sm-12 col-xs-12  ps-0">
        <div className="address-box clearfix">
          <div className="add-icon">
          <a href="/">
          <img className="img-fluid d-md-none mx-auto d-block" src="/images/SVAlogo.png" alt="Logo" style={{maxWidth: '2.813rem', height: 'auto',display: 'flex'}}/>
          <img className="img-footer-fluid d-none d-md-block header-logo d-flex flex-column justify-content-center align-items-center  text-center" src="/images/SVAlogoWithName.png" alt="Logo"/></a>
          </div>
          <div className=" d-flex align-items-center justify-content-center">
          <div className="img-fluid d-md-none text-nowrap text-center" style={{color: '#ffffff', textAlign: 'center', fontFamily: 'sans-serif,"Montserrat-Regular"', fontSize: '18px', fontWeight: 400, display: 'flex', alignItems: 'center',}}>
            Sri Lanka’s 1st Social Trading Platform
        </div>
        <div className="img-footer-fluid d-none d-md-block" style={{color: '#ffffff', textAlign: 'left', fontFamily: 'sans-serif,"Montserrat-Regular"', fontSize: '18px', fontWeight: 400, display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
          Sri Lanka’s 1st Social Trading Platform
        </div>
          </div>
 
        </div>
      </div>
      <div class="col-lg-12 col-sm-12 col-xs-12 pt-4 d-md-none">
      <div className="pt-4 d-md-none text-center" style={{color: '#ffffff', textAlign: 'center', fontFamily: 'sans-serif,"Montserrat-Light', fontSize: '16px', fontWeight: 100, display: 'flex', alignItems: 'center'}}>
          Join the revolution in trading and be part of Sri Lanka’s first-ever social
          trading platform. Get real-time insights<br /> into the market, connect with top
          traders, stay updated with stock news, and grow your portfolio—together.
        </div>
        </div>
        <div class="col-lg-8 col-sm-12 col-xs-12 pt-4 px-4 d-none d-md-block">
        <div className="pt-4 d-none d-md-block" style={{color: '#ffffff', textAlign: 'left', fontFamily: 'sans-serif,"Montserrat-Light', fontSize: '16px', fontWeight: 100, display: 'flex', alignItems: 'left'}}>
          Join the revolution in trading and be part of Sri Lanka’s first-ever social
          trading platform. Get real-time insights<br /> into the market, connect with top
          traders, stay updated with stock news, and grow your portfolio—together.
        </div>
      </div>
      <div classname="p-3">&nbsp;</div>
    </div>
    <div classname="p-3">&nbsp;</div>
</div>
</footer>
 <Browser size={size} />   
</>
       
    );
};

export default Footer;


// export default ForgetPassword;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext"; // Import useAuth
import "./forgotpw.css"; 
import { Base_URL } from "../../Components/BaseURL";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function ForgetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newCPassword, setCNewPassword] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); // Use login from context
  const username = localStorage.getItem('unm');
  
  useEffect(() => {
    document.title = "stockwiseanalytics.com - Change Password Page";

    // Extract token and email from URL
    const params = new URLSearchParams(window.location.search);
    setToken(params.get('token') || "");
    setEmail(params.get('email') || "");
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    try {
      if (!newPassword || newPassword === "" || !newCPassword || newCPassword === "") {
        toast.warning("All Fields are Required");
        return;
      }
      // Make sure token and email are available
      if (!token || !email) {
        toast.error("Missing token or email");
        return;
      }
      
      const res = await axios.post(`${Base_URL}/apirouter/resetpw`, {
        username: username,
        token: token,
        email: email,
        newpassword: newPassword,
        newcpassword: newCPassword               
      });

      if (res.status === 200) {
        setNewPassword("");
        setCNewPassword("");
        toast.success("Password Changed Successfully, Redirecting...");
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error Changing Password: ", error);
      toast.error(error.response?.data?.message || "Error Changing Password");
    }
  };

  function handleClick() {
    navigate("/home");
  }

  return (
    <div className="Profilesettings p-3 mb-2 mt-5 bg-black text-white">
      <div className="container mt-1">
        <div className="bg-black row centered-element">
          <div className="bg-black col-xxl-6">
            <video
              className="videoTag"
              autoPlay
              loop
              muted
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            >
              <source src="/videos/login.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="col-xxl-6 row flex-column align-items-center ml-20 bg-black min-vh-100">
            <div className="input-group mb-3 flex-column align-items-center" style={{ top: '20px', textAlign: 'center', width: '100%', background: 'linear-gradient(to bottom right, black, #282727)', background: 'linear-gradient(to bottom, transparent 1%, black 100%)' }}>
            <div className="col-12 justify-content-center align-items-center" style={{ textAlign: 'center' }}>
                <div class="justify-center items-center inline-flex">
  
</div>
<div class="container mt-3"></div><div class="container mt-3"></div>
<a href="/"> 
<img src="/images/login/logo-05-10.png" class="mx-auto d-block" 
// onClick={handleClick} 
alt="Logo" width="280" style={{ textAlign: 'center',objectfit: 'cover' }}/> </a>
              </div>
              <div class="container mt-3"></div>
              <div className="container mt-3"></div>
              <form onSubmit={handlePasswordChange} className='login-form' style={{ width: '50%' }}>
                <div className="input-group mb-15">
                  <div className="error-setup text-nowrap"><p id='err' className='error-message'></p></div>
                </div>
                <div className="container mt-3"></div>
                <div className="LogIn w-100 h-5 text-center text-neutral-300 text-3xl signuptitle">Reset your password</div>
                <div className="p-2"></div>
                <div className='form-outline mb-3'>
                  <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                    <div className='input-group d-flex align-items-center p-0'>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control w-60 text-white bg-black border border-0 no-border fw-light"
                        id="newpassword"
                        placeholder="New Password"
                        name="newpassword"
                        onChange={(evt) => setNewPassword(evt.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-outline mb-3'>
                  <div className="px-1 py-1 rounded-3 border border-1 border-white border-opacity-50 col-xxl-12 row align-items-center d-flex">
                    <div className='input-group d-flex align-items-center p-0'>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control w-60 text-white bg-black border border-0 no-border fw-light"
                        id="cnewpassword"
                        placeholder="Confirm New Password"
                        name="cnewpassword"
                        onChange={(evt) => setCNewPassword(evt.target.value)}
                      />
  <span class="p-viewer">
  <button
                  className='btn btn-outline-secondary'
                  type='button'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className='me-2' />
                </button>

  </span>





                    </div>
                  </div>
                </div>


                <div className="p-2"></div>
                <div className="p-4"></div>
                <div className="input-group mb-3">
                  <div className="px-1 py-1 w-80 bg-success-subtle rounded-3 border border-1 border-white border-opacity-50 col-xxl-11 justify-content-center align-items-center d-flex">
                    <button type="submit" className="mx-auto d-block btn btn-block mt-0 p-0">Save Password</button>
                  </div>
                </div>
                <div className="p-4"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgetPassword;

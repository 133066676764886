import React, { useState, useEffect,useContext } from "react";
import './landing.css';
import LandingSectionOne from './LandingSectionOne';
import LandingSectionTwo from './LandingSectionTwo';
import LandingSectionThree from './LandingSectionThree';
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
function Landing() {
  const size = useWindowSize();
    useEffect(() => {
        document.title = "stockwiseanalytics.com - Home"; // Dynamically changes the title
      }, []);
      const ColoredLine = ({ color }) => (
        <hr
          style={{
            color: 'rgba(256,256,256, 0.3)',
            backgroundColor: 'rgba(256,256,256, 0.3)',
            height: 1,
          }}
        />
      );
    return (
        <>  
         <div className="container-fluid">    
         <div className="row">
         <div className="col-md-12">
            <LandingSectionOne/>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
            <LandingSectionTwo/>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
            <LandingSectionThree/>
            </div>
            </div>
            </div>
            <Browser size={size} />
                 </>
    );
}

export default Landing;
// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChangeEmailDialogPage from './ChangeEmailDialogPage'; 
const SendOTPDialogPage = ({ open, onClose, stockData, onUpdate }) => {
  const [tphone, setTphone] = useState('');
  const [teml, setEml] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [showChangeEmailDialog, setshowChangeEmailDialog] = useState(false);
  const [formDataCode, setformDataCode] = useState({
    InputEmail:"",
    InputOTP:"",
  });
  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState();
  const [rgvalue, setRGValue] = useState('phone');
  const username = localStorage.getItem('unm');    
  useEffect(() => {
    setformDataCode(prevData => ({
      ...prevData,
    }));
  }, [formDataCode.InputOTP]);

  useEffect(() => {
    if (stockData) {
      setformDataCode({
        InputEmail: username ,
        InputOTP:stockData.InputOTP,
      });
       }
  }, [stockData]);

  const handleSendCode = async () => {
    try {
      console.log('-------username---'+username+'---'+formDataCode.InputOTP);
  const res = await axios.post(Base_URL + `/apiRouter/updemail`, {username: username, code : formDataCode.InputOTP});
     // onClose();
      console.log('----status------------',res.status);
      setshowChangeEmailDialog(true);
    } catch (error) {
      toast.error("Error sending confirmation code. Please try again later.");
      console.error(error);
      return;
    }
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
      <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Change Email</Typography>
        <div className="col-auto font-family-sans-serif text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <ToastContainer />
            <form className="form-inline">
               <div className="row g-3 align-items-center">
                <div className="form-group row">
                  <div className="col-12">
                  <p className="text-success fs-6 fw-normal font-family-sans-serif float-start text-start">Email reset link sent. Please check your email inbox for the code.</p>
                    <p className="text-black fs-6 fw-normal font-family-sans-serif float-start text-start">Enter the confirmation code you received.</p>
                  </div>
                     </div>
                <div className="form-group row">
                  <div className="col-6">

                  <div className="col-6">
                  <input
  type="number"
  className="form-control text-black bg-white fw-light w-100"
  id="InputOTP"
  name="InputOTP"
  value={formDataCode.InputOTP}
  onChange={(e) => setformDataCode({ ...formDataCode, InputOTP: e.target.value })}
/>

                  </div>
                  <h6 className="mt-1 mb-1 d-flex justify-content-start">
            <small>
          New code can be requested in 150 seconds.
          </small>
          </h6>
                </div>
              </div>

              <div className="col-auto font-family-sans-serif text-end">
                <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSendCode}>Submit</Button>
              </div>
              </div>
            </form>
      </DialogContent>
    </Dialog>
    <ChangeEmailDialogPage
        open={showChangeEmailDialog}
        onClose={() => setshowChangeEmailDialog(false)} // Close the dialog
        stockData={stockData}
        onUpdate={onUpdate} // Pass any required props
      />
              </>
  );
};

export default SendOTPDialogPage;

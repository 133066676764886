// export default FinancialInfo;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import { BaseURL_twitter } from '../../Components/BaseURL_twitter.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import { TwitterShareButton } from "react-share";

const MarketSentiment = ({ initialUserData }) => {
    const [fininfor, setFininfor] = useState([]);
    const [matchingData, setMatchingData] = useState({});

    const username = localStorage.getItem('unm');
    const symbols = localStorage.getItem('symbolstring');
    const localLogoImagePath = "/images/companylogos";
    let resultString = '';
    if (symbols) {
        let inputString = symbols.replace(/[() ]/g, '');
        let arrayOfStrings = inputString.split(',');
        arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
        resultString = arrayOfStrings.join(',');
    }

    useEffect(() => {
        if (!resultString) return; // Exit if resultString is empty
        const fetchFiniData = async () => {
            try {
                const res = await axios.get(Base_URL + "/portfolio/msentiment", {
                    params: { username: username, symbols: resultString }
                });
                setFininfor(res.data);
                // console.log('---data----'+JSON.stringify(res.data));
  
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        if (resultString) {
            fetchFiniData();
        }
    }, [username, resultString]);
    const handleShare = () => {
            axios.post(`${BaseURL_twitter}`, {})
              .then(res => {
                const url = res.data.imageUrl;
                const twitterURL = `https://twitter.com/share?url=${url}&text=Check%20out%20this%20image&hashtags=yourHashtags`;
                console.log('--twitterURL--'+twitterURL);
                window.open(twitterURL, "twitter");
              })
              .catch(err => console.log(err, "Error trying to tweet"));
        
      };
      
  let loadImage = function(variable) {
    const url_image = `/images/companylogos/${variable}.svg`;

    return (
        <img
            className="rectangle-154 m-1"
            style={{
                borderRadius: '8px',
                flexShrink: 0,
                width: '48px',
                height: '48px',
                position: 'relative',
                objectFit: 'cover',
            }}
            src={url_image}
            alt="Company Logo"
            onError={(e) => { e.target.src = '/images/companylogos/default-logo.png'; }}
        />
    );
};
    return (
        <>
        <div class="container-fluid px-4">
        <div className="row px-4">
            <p className="text-white fs-6 fw-bold font-family-sans-serif">
            MARKET SENTIMENT
            </p>
            </div>
            <div className="row h-25 px-4">
            <div className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-2 py-2 " style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
                    <table id="example" className="table table-striped table-dark font-family-sans-serif mytable">
                                  <tbody>
                        {fininfor.length === 0 ? (
                            <tr>
                                <td colSpan="9">No data available</td>
                            </tr>
                        ) : (
                            fininfor.map(fininf => (
                                <tr key={fininf.id} className="border-bottom border-secondary border-opacity-25">
                                    <td>
                                        {/* <img
                                            src={fininf.ctick ? `${localLogoImagePath}/${fininf.ctick}.png` : 'images/companylogos/default-logo.png'}
                                            style={{
                                                borderRadius: "8px",
                                                flexShrink: "0",
                                                width: "48px",
                                                height: "48px",
                                                position: "relative",
                                                objectFit: "cover",
                                              }}
                                        /> */}
                                         {loadImage(fininf.ctick)}
                                        {fininf.ctick}
                                    </td>
                                    <td>{fininf.csemc_name}<br />The {fininf.csemc_name} is also in {fininf.portpolios} portfolios</td>
                    <td>     <TwitterShareButton
              url={"https://www.stockwiseanalytics.com"}
             // title=`Hey, ${fininf.ctick} is in <number of portfolios the ticker is in> SWA portfolios! Create your Portfolio at https://www.stockwiseanalytics.com/`
              title={`Hey, ${fininf.ctick} is in ${fininf.portpolios} SWA portfolios! Create your Portfolio at`}
              onClick={handleShare}
              // onClick={shareOnTwitter}
              className=" Demo__some-network__share-button font-family-sans-serif"
            >
          
              <i className="btn btn-secondary bi bi-twitter-x font-weight-normal" style={{ color: '#fff', fontSize: '1rem' }}>&nbsp;&nbsp;&nbsp;Share</i>
            </TwitterShareButton></td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            </div>
            </div>
        </>
    );
};

export default MarketSentiment;

import React from 'react';
import './landing.css';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
function Explorefirstedge() {
  const navigate = useNavigate();
  function navigateTo(path) {
      navigate(path);
    }
  function handleExplore(evt) {
      navigateTo("/alertmng");
    }
    return (
        <>

        
        <div style={{ height: '500px', position: 'relative', width: '100%', marginTop: "90px" ,backgroundColor:"black" }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    position: 'absolute', 
                    top: 10, 
                    left: '1%', 
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/">
            Home</Link>
            <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >Services</Typography>
        <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >FirstEdge (Market Alerts Services)</Typography>
      </Breadcrumbs>
       
        <video className='videoTag' autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                <source src='/videos/landing.mp4' type='video/mp4' />
            </video>
     
        <div className="text-center">
            <div className='col-12' class="trendunner">
                <span class="trendunner1">Explore our&nbsp;</span> <span class="trendunner2">FirstEdge</span>
            </div>
        </div>
        </div>
  
  <div class="container-fluid py-3 trendunnersmall">
    <p class="text-center  m-0 px-3 py-2">
        <span class="trendunnersmall1">
        FirstEdge Alerts are designed to&nbsp;
        </span>
        <span class="trendunnersmall2">
        keep you informed about crucial market movements in real-time.&nbsp;
        </span>
        <span class="trendunnersmall1">
        With instant notifications, you can make timely and informed investment decisions, ensuring you never miss an important opportunity.&nbsp;
        </span>
        <span class="trendunnersmall2">
        Our proactive alert system helps you stay ahead of the market curve, providing the edge you need for successful investing.
        </span>
    </p>
</div>

<div className="bg-black ">
                <div className='container' >
                    <div className="row text-black text-center">
<div className="col-lg-2"></div> 
                    <div className="col-lg-4 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-black rounded-4 border border-1   bg-white" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/trendrunner/portfolio.png" alt="CSE" className="img-fluid rounded-4" />
                                </div>
                                <div className="card-body">
                                <div className='p-4'></div> <div className='p-3'></div>
                                <div id="component" class="container-fluid py-3">
    <p class="text-black fs-4 fw-semibold font-family-sans-serif m-0 px-3 py-3">
    Manage your own Alerts
    </p>
</div>
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-black fs-6 fw-normal font-family-sans-serif">
        Create, View, Edit and Delete&nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-sans-serif">
        your preferred alerts for shares based on the company.
        </span>
 
    </p>
</div>

<div id="component" class="container-fluid py-3">
    <div class="position-relative  row">
        <div class="left-0 top-0 position-absolute bg-purple rounded-1 col-12">
        </div>
        <p class="position-absolute text-center text-white fs-6 fw-bold font-family-sans-serif col-8 m-0 px-3 py-2  rounded-1" style={{backgroundColor:'rgba(102, 42, 159, 1)'}}>
        Explore
        </p>
    </div>
    <div className='p-3'></div>
</div>
                                </div>
                            </div>
                            </a>
                        </div>




                        <div className="col-lg-4 d-flex justify-content-center align-items-center">
                        <a href="#" target="_self" onClick={handleExplore} style={{ textDecoration: 'none' }}> 
                            <div className="card text-center text-black rounded-4 border border-1   bg-white" style={{ backgroundColor: '#FFFFFF15' }} >
                                <div className="card-image">
                                    <img src="/images/trendrunner/volinfo.png" alt="CSE" className="img-fluid rounded-4" />
                                </div>
                                <div className="card-body">
                                <div id="component" class="container-fluid py-2">
    <p class="text-black fs-4 fw-semibold font-family-sans-serif m-0 px-3 py-1">
    3 Types of Alerts
    </p>
</div>
    <div id="component" class="container-fluid py-3">
    <p class="text-start  m-0 px-3 py-2">
        <span class="text-black fs-6 fw-normal font-family-sans-serif">
        1.Price Alerts: &nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-sans-serif">
        Get notified when the price meets the defined threshold.<br />
        </span>
        <span class="text-black fs-6 fw-normal font-family-sans-serif">
        2.Dividend Alerts: &nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-sans-serif">
        Get notified on the dividends.<br />
        </span>
        <span class="text-black fs-6 fw-normal font-family-sans-serif">
        3.Financial and News Alerts: &nbsp;
        </span>
        <span class="text-black fs-6 fw-bold font-family-sans-serif">
        Get notified when the price meets the defined threshold.
        </span>
    </p>
</div>

<div id="component" class="container-fluid py-3">
    <div class="position-relative  row">
        <div class="left-0 top-0 position-absolute bg-purple rounded-1 col-12">
        </div>
        <p class="position-absolute text-center text-white fs-6 fw-bold font-family-sans-serif col-8 m-0 px-3 py-2  rounded-1" style={{backgroundColor:'rgba(102, 42, 159, 1)'}}>
        Explore
        </p>
    </div>
    <div className='p-3'></div>
</div>
                                </div>
                            </div>
                            </a>
                        </div>
                        <div className="col-lg-2"></div> 

  



                        
                    </div>
                </div>
            </div>

</>
    );
}

export default Explorefirstedge;
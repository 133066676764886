// export default FinancialInfo;
import React, { useState, useEffect , useContext} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from "../../Components/BaseURL.js";
//import fetchCPrice from "./fetchCurrentPrice";
import { usePortfolio } from "../../PortfolioContext";

const FinancialInfo = () => {
    const { stocks, currentPrices, currentVolumes } = usePortfolio();
    const [fininfor, setFininfor] = useState([]);
    const [matchingData, setMatchingData] = useState({});
    const [symbols, setSymbols] = useState([]);
    const username = localStorage.getItem("unm");
    const localLogoImagePath = "/images/companylogos";

    // Utility function to format symbols as a string for API
    const getResultString = (symbols) => {
        if (symbols.length === 0) return "";
        return symbols.map((str) => `'${str}'`).join(",");
    };
    // console.log('--currentPrices---'+JSON.stringify(currentPrices));
    // Update symbols whenever stocks change
    useEffect(() => {
        if (stocks.length > 0) {
            const fetchedSymbols = stocks.map((stock) => stock.ctick);
            setSymbols(fetchedSymbols);
        }
    }, [stocks]);

    // Calculate resultString whenever symbols change
    const resultString = getResultString(symbols);
    useEffect(() => {
//console.log("--resultString---", resultString);
    }, [symbols]);


// Fetch financial info based on resultString
useEffect(() => {
    const fetchFiniData = async () => {
        try {
            const res = await axios.get(Base_URL + "/fininfo/getfininfo", {
                params: { username, symbols: resultString }
            });

            setFininfor(res.data);

            // Map financial info to currentPrices from context
            const pricesObject = res.data.reduce((acc, fininf) => {
                const priceData = currentPrices.find(
                    (price) => price.symbol === fininf.stock_name
                );
                const currentPrice = priceData?.close_prices?.cptoday || null;
                return { ...acc, [fininf.stock_name]: currentPrice };
            }, {});

            setMatchingData(pricesObject);
        } catch (error) {
            console.error("Error fetching financial data:", error);
            toast.error("Failed to fetch data");
        }
    };

    if (symbols.length > 0 && username && currentPrices.length > 0) {
        fetchFiniData();
    }
}, [username, resultString, currentPrices, symbols]);


    // Load company logo or default logo
    const loadImage = (variable) => {
        const url_image = `${localLogoImagePath}/${variable}.png`;

        return (
            <img
                className="rectangle-154"
                style={{
                    borderRadius: "8px",
                    flexShrink: 0,
                    width: "48px",
                    height: "48px",
                    position: "relative",
                    objectFit: "cover",
                }}
                src={url_image}
                alt={`${variable} Logo`}
                onError={(e) => {
                    e.target.src = `${localLogoImagePath}/default-logo.png`;
                }}
            />
        );
    };

    return (
        <div className="container-fluid px-4">
            <p className="text-white fs-6 fw-bold font-family-sans-serif px-4">
                FINANCIAL INFORMATION
            </p>

            <div className="row h-25 w-100 px-4">
                <div
                    className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 m-0 py-2"
                    style={{
                        backgroundColor: "black",
                        width: "100%",
                        height: "300px", overflow: 'auto'
                    }}
                >
                    <table
                        className="table table-striped table-dark font-family-sans-serif mytablefi"
                        style={{ overflowX: "hidden" }}
                    >
                        <thead>
                            <tr className="border-bottom border-primary-subtle border-opacity-25">
                                <th className="fininfo_header" width={"10%"}>
                                    Stock
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Market Capitalization (LKR)
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Earnings Per Share (LKR)
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Net Asset Value (LKR)
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Price-to-Earnings Ratio
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Price-to-Book Ratio
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Dividend Yield
                                </th>
                                <th className="fininfo_header" width={"20%"}>
                                    Top Shareholders
                                </th>
                                <th className="fininfo_header" width={"10%"}>
                                    Share Ownership
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fininfor.length === 0 ? (
                                <tr>
                                    <td colSpan="9">No data available</td>
                                </tr>
                            ) : (
                                fininfor.map((fininf) => (
                                    <tr
                                        key={fininf.id}
                                        className="border-bottom border-secondary border-opacity-25"
                                    >
                                        <td width={"10%"} nowrap>
                                            {loadImage(fininf.stock_name)}
                                            &nbsp;&nbsp; {fininf.stock_name}
                                        </td>
                                        {/* <td
                                            className="fininfo_dtl p-0 m-0"
                                            width={"13%"}
                                        >
                                            {Math.round(
                                                fininf.market_capitalization *
                                                    matchingData[
                                                        fininf.stock_name
                                                    ]
                                            ).toLocaleString()}
                                        </td> */}
                                        <td width={"13%"}>
    {matchingData[fininf.stock_name]
        ? Math.round(
              fininf.market_capitalization *
              matchingData[fininf.stock_name]
          ).toLocaleString()
        : "N/A"}
</td>
                                        <td
                                            className="fininfo_dtl p-0 m-0"
                                            width={"10%"}
                                            style={{ textAlign: "center" }}
                                        >
                                            {fininf.earning_per_share}
                                        </td>
                                        <td
                                            className="fininfo_dtl"
                                            width={"10%"}
                                            align="center"
                                        >
                                            {fininf.net_asset_values}
                                        </td>
                                        {/* <td
                                            className="fininfo_dtl"
                                            width={"10%"}
                                            align="center"
                                        >
                                            {matchingData[fininf.stock_name]
                                                ? (
                                                      matchingData[
                                                          fininf.stock_name
                                                      ] /
                                                      fininf.earning_per_share
                                                  ).toFixed(2)
                                                : "N/A"}
                                        </td> */}
                                        <td
    className="fininfo_dtl"
    width={"10%"}
    align="center"
>
    {matchingData[fininf.stock_name] && fininf.earning_per_share
        ? (matchingData[fininf.stock_name] / fininf.earning_per_share).toFixed(2)
        : "N/A"}
</td>

                                        <td
                                            className="fininfo_dtl"
                                            width={"10%"}
                                            align="center"
                                        >
                                            {matchingData[fininf.stock_name]
                                                ? (
                                                      fininf.net_asset_values /
                                                      matchingData[
                                                          fininf.stock_name
                                                      ]
                                                  ).toFixed(2)
                                                : "N/A"}
                                        </td>
                                        <td
                                            className="fininfo_dtl"
                                            align="center"
                                        >
                                            {matchingData[fininf.stock_name]
                                                ? (
                                                      (fininf.dividend_per_share /
                                                          matchingData[
                                                              fininf.stock_name
                                                          ]) *
                                                      100
                                                  ).toFixed(2) + "%"
                                                : "N/A"}
                                        </td>
                                        <td
                                            className="fininfo_dtl td_start text-nowrap"
                                            width={"20%"}
                                        >
                                            {fininf.top_shareholders}
                                        </td>
                                        <td
                                            className="fininfo_dtl"
                                            align="center"
                                            width={"10%"}
                                        >
                                            {parseInt(
                                                fininf.share_ownership * 100
                                            )}
                                            %
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FinancialInfo;

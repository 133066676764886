import React, { useState, useEffect  } from "react";
import './about-us.css'
import SectionOne from './sectionOne';
import SectionTwo from './sectionTwo';
import { useWindowSize } from "@uidotdev/usehooks";

function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 ,marginBottom: 0,}}
    />
  );
}
function AboutUs() {
  const size = useWindowSize();
  useEffect(() => {
    document.title = "stockwiseanalytics.com - About Us"; // Dynamically changes the title
  }, []);
    return (
        <>

<div className="container-fluid">   
<div className="row">
<div className="col-md-12">
         <SectionOne />
            </div>
            </div>
            <div className="row">
            <div className="col-md-12">
         <SectionTwo/>
            </div>
            </div>
            </div>
            <Browser size={size} />
</>

    );
}

export default AboutUs;
// export default PortfolioCreation;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from '../../Components/BaseURL.js';
import { Input } from "reactstrap";
import { useWindowSize } from "@uidotdev/usehooks";
function Browser({ size }) {
  return (
    <div
      data-testid="browser"
      className="browser"
      style={{ width: size.width / 4, height: size.height / 4 }}
    />
  );
}
const PortfolioCreation = ({ updateData }) => {
  const size = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const username = localStorage.getItem('unm');
  const navigate = useNavigate();  // Use react-router's useNavigate for navigation
  const [count, setCount] = useState(0);
  const [stocks, setStocks] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formDataStocks, setFormDataStocks] = useState({
    InputStock: "",
    InputQty: "",
    InputAvgPrice: "",
    username: localStorage.getItem('unm')
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const stockRes = await axios.get(Base_URL + `/masterdata/stocks`, {
        params: { username: username }
      });
      setData(stockRes.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Failed to load data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (updateData) {
      setFormDataStocks({
        InputStock: updateData.id,
        InputQty: updateData.qty,
        InputAvgPrice: updateData.avgp,
        username: localStorage.getItem('unm')
      });
    }
  }, [updateData]);

  const postStockData = async (formDataStocks) => {
    if (isSubmitting) return; // Prevent multiple calls
        setIsSubmitting(true);
    try {
      const response = await axios.post(Base_URL + `/portfolio/poststock`, formDataStocks);
      if (response.status === 200 || response.status === 201) {
        toast.dismiss(); // Clear all existing toasts
        toast.success("Stock Data Successfully inserted..");
      } else {
        throw new Error("Unexpected response");
      }
      
      ///////////
      // Fetch stock data after portfolio creation
      try {
        const username = localStorage.getItem('unm');
        if (username) {
          const res = await axios.get(`${Base_URL}/portfolio/stocks`, {
            params: { username }
          });
          setStocks(res.data);
          const symbols = res.data.map(stock => stock.ctick);
          localStorage.setItem('symbolstring', symbols); // Store symbols for further use
        }
      } catch (error) {
        console.error("Error fetching stock data: ", error);
      } finally {
        setIsSubmitting(false); // Re-enable the button
      }
      ///////////

      // Refresh the entire page using navigation after successful form submission
      navigate(0);  // This will refresh the dashboard by re-navigating to the current route
      navigate('/portfolio');
       // Fetch new data after submission instead of reloading the page
     postStockData();
    } catch (error) {
      toast.dismiss(); // Clear all existing toasts
      toast.error("Error submitting data. Please try again later.");
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      postStockData(formDataStocks);
    }
  };

  const handleCountrySelect = (selectedStock) => {
    setFormDataStocks({
      ...formDataStocks,
      InputStock: selectedStock,
    });
  };

  return (
    <> 
   <div className="container">
   <p className="text-white fs-6 fw-bold font-family-sans-serif px-3">
   CREATE A PORTFOLIO </p>
  
  <div className="p-3"></div>
  <div className="row h-25 w-100 scrollevent px-3">
      <form className="form-inline px-3" onSubmit={handleSubmit}>
  <div className="row">
    {/* Stock Selector */}
    <div className="col-md-3">
        <label
          htmlFor="InputStock"
          style={{
            color: "#ffffff",
            fontFamily: "sans-serif,'Montserrat-Regular'",
            fontSize: "16px",
            fontWeight: "400",
            whiteSpace: 'nowrap',
            justifyContent: "flex-start",
            position:'relative',
            
          }}
        >
          Stock
        </label>
        </div>
        <div className="col-md-4">
        <select
          id="InputStock"
          className="form-select text-white bg-black fw-light px-0"
          required
          style={{
            fontSize: "1rem",
            borderRadius: "10px",
            flexShrink: "0",paddingRight: '12px',
          }}
          onChange={(e) => handleCountrySelect(e.target.value)}
          name="InputStock"
          value={formDataStocks.InputStock}
        >
          <option value="">Select a Stock</option>
          {data.map((stocks) => (
            <option
              key={stocks.csem_id}
              value={stocks.csemc_id}
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                fontWeight: "400",
              }}
            >
              {stocks.csemc_name}
            </option>
          ))}
        </select>
  
    </div>

    {/* Quantity Input */}
    <div className="col-md-2">
  
        <label
          htmlFor="InputQty"
            style={{
            color: "#ffffff",
  
            fontFamily: "sans-serif,'Montserrat-Regular'",
            fontSize: "16px",
            fontWeight: "400",
          }}>Quantity</label>
        </div>
        <div className="col-md-2">
        <input
          type="number"
          className="form-control text-white bg-black fw-light"
          id="InputQty"
          required
          name="InputQty"
          value={formDataStocks.InputQty}
          onChange={(e) =>
            setFormDataStocks({ ...formDataStocks, InputQty: e.target.value })
          }
          style={{
            borderRadius: "10px",
            fontSize: 14,
            fontFamily: "sans-serif,'Montserrat-Regular'",
            borderStyle: "solid",
            borderColor: "#ffffff",
            borderWidth: "1px",
            width: "100px",
            background: "unset",
          }}
        />
      </div>
    </div>
    <div className="p-2"></div>
    <div className="row">
    {/* Avg. Price Input */}
    <div className="col-md-3">
         <label
          htmlFor="InputAvgPrice"
          style={{
            color: "#ffffff",
            fontFamily: "sans-serif,'Montserrat-Regular'",
            fontSize: "16px",
            fontWeight: "400",
             whiteSpace: "nowrap",
          }}
        >
          Avg. Price(LKR)
        </label>
        </div>
        <div className="col-md-1">
        <input
          type="number"
          className="form-control text-white bg-black fw-light"
          id="InputAvgPrice"
          required
          name="InputAvgPrice"
           value={formDataStocks.InputAvgPrice}
          onChange={(e) =>
            setFormDataStocks({ ...formDataStocks, InputAvgPrice: e.target.value })
          }
          style={{
            borderRadius: "10px",
            fontSize: 14,
            fontFamily: "sans-serif,'Montserrat-Regular'",
            borderStyle: "solid",
            borderColor: "#ffffff",
            borderWidth: "1px",
            width:"5rem",
            background: "unset",
          }}
        />
      </div>
    {/* Submit Button */}
    <div className="col-md-8">
      <button
        type="submit"
        className="btn text-white float-end me-4"
        style={{
          fontFamily: "sans-serif,'Montserrat'",
          background: "rgba(217, 217, 217, 0.10)",
          borderRadius: "10px",
          }}
      >
        Add Record
      </button>
    </div>

  </div>
  <ToastContainer />
</form>
</div>
<div className="p-2">&nbsp;</div>
</div>
<Browser size={size} />
    </>

  );
};

export default PortfolioCreation;

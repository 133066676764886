// export default EditDialog;
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { Close } from '@material-ui/icons';
import axios from "axios";
import { Base_URL } from '../../Components/BaseURL.js';
import TextField from "@material-ui/core/TextField";
import EditConfirmDialog from "./EditConfirmDialog_add.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
const EditDialog_add = ({ open, onClose, stockData, onUpdate }) => {
  const [editDialogOpen_add, setEditDialogOpen_add] = useState(false);
  const [stocks, setStocks] = useState([]);
    const min = 1;
 const Inputmax = "";
 const [isClicked, setIsClicked] = useState(false);
 const [initialFormData, setInitialFormData] = useState(null);
  const [formDataStocks, setFormDataStocks] = useState({
    InputOrdID:"",
    InputStock: "",
    InputQty: "",
    InputAvgPrice: "",
    InputCurrentPrice:"100",
    InputStockName: "",
    username: localStorage.getItem('unm'),
    Inputmax:"",
    InputNewQty:"",
    InputNewPQty:"",
    InputNewAvgPrice:"",
    InputNewPAvgPrice:"",
  });
  const [updateData, setUpdateData] = useState(null);
  const [value, setValue] = useState();
  // Effect for handling re-calculations of New Position Quantity
  useEffect(() => {
    //const newPQty = parseInt(formDataStocks.InputQty) + parseInt(formDataStocks.InputNewQty || 0); // Ensure default to 0 if InputNewQty is undefined
    setFormDataStocks(prevData => ({
      ...prevData,
    //  InputNewPQty: newPQty.toString()  // Ensuring it's set as a string if necessary
    }));
  }, [formDataStocks.InputQty, formDataStocks.InputNewQty]);  // Dependency array
  useEffect(() => {
    if (stockData) {



      const initialData = {
        InputOrdID:stockData.ordid,
        InputStock: stockData.ctick,
        InputQty: stockData.csemco_quantity,
        InputAvgPrice: stockData.avgprice,
        InputCurrentPrice: '100',
        InputStockName: stockData.cname,
        username: localStorage.getItem('unm'),
        Inputmax:stockData.csemco_quantity,
        InputNewQty:"0",
        InputNewPQty:"0",
        InputNewAvgPrice:"0.00",
        InputNewPAvgPrice:"0.00"
      };
      setFormDataStocks(initialData);
      setInitialFormData(initialData);
    }
  }, [stockData]);

 // Handle changes in form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
if (e.target.name=='InputQty')
  {
       // e.target.value=formDataStocks.Inputmax;
       // formDataStocks.InputNewPAvgPrice = stockData.avgprice; 
                                                                 
      if (parseInt(e.target.value) > formDataStocks.Inputmax) 
              {
                e.target.value = formDataStocks.Inputmax;
              }

          if (parseInt(e.target.value) < 1) 
            {
              e.target.value = 1;   
            } 
            formDataStocks.InputNewPQty = parseInt(e.target.value) + parseInt(formDataStocks.InputNewQty || 0); 
            formDataStocks.InputNewPAvgPrice = (parseFloat(parseInt(formDataStocks.InputNewAvgPrice)*formDataStocks.InputNewQty + parseInt(e.target.value)*formDataStocks.InputAvgPrice)/formDataStocks.InputNewPQty).toFixed(2); 
             
  
          }

          if (e.target.name=='InputNewQty')
            {
            formDataStocks.InputNewPQty = parseInt(e.target.value) + parseInt(formDataStocks.InputQty || 0); 
            formDataStocks.InputNewPAvgPrice = (parseFloat(parseInt(e.target.value)*formDataStocks.InputNewAvgPrice + parseInt(formDataStocks.InputQty)*formDataStocks.InputAvgPrice)/formDataStocks.InputNewPQty).toFixed(2); 
             
            }
            if (e.target.name=='InputNewAvgPrice')
              {
              formDataStocks.InputNewPAvgPrice = (parseFloat(parseInt(e.target.value)*formDataStocks.InputNewQty + parseInt(formDataStocks.InputQty)*formDataStocks.InputCurrentPrice)/(parseInt(formDataStocks.InputNewPQty))).toFixed(2); 
              
              }

            




    setFormDataStocks(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
// Handle changes in form inputs
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const res = await axios.post(Base_URL + `/portfolio/updatestock`, formDataStocks);
  //     toast.success("Stock Data Successfully updated.");
  //     setIsClicked(false)
  //     onUpdate(formDataStocks); // Pass updated data to parent
  //      onClose(); // Close the modal
  //      window.location.reload();
  //      } catch (error) {
  //     //toast.error("Error submitting data. Please try again later.");
  //     console.error(error);
  //   }
  // };
  const closeEditDialog_add = () => {
    setEditDialogOpen_add(false);
    setUpdateData(null);
    window.location.reload();
  };
    const handleStockModal = (formDataStocks) => {
      if (JSON.stringify(initialFormData) === JSON.stringify(formDataStocks)) {
        toast.info("No changes detected, confirmation prevented.");
        return;  // Exit the function if no changes were made
      }
      if (formDataStocks.InputNewQty.value ==='0' || formDataStocks.InputNewAvgPrice==='0.00' ) {
        toast.info("Please fill Required field/s.");
        return;  // Exit the function if no changes were made
      }
    setIsClicked(true)
    setUpdateData(formDataStocks);
    setEditDialogOpen_add(true);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
    >
            <DialogTitle id="customized-dialog-title">
        <Typography variant="h6">Add a portfolio record</Typography>
        <div className="col-auto font-family-sans-serif text-end" style={{ position: 'absolute', right: 8, top: 0, color: (theme) => theme.palette.grey[500] }}>
        <IconButton aria-label="close"  onClick={onClose}>
        <Close/>
        </IconButton></div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="p-2"></div>
        <ToastContainer />
        <form 
        class="form-inline">
          <input type="hidden" name="InputOrdID" id="InputOrdID"  value={formDataStocks.InputOrdID}></input>
          <input type="hidden" name="InputStock" id="InputStock" value={formDataStocks.InputStock}></input>
          <input type="hidden" name="Inputmax" id="Inputmax" value={formDataStocks.InputQty}/>
          <div className="row g-3 align-items-center">
          <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">Stock</p>
            </div>
            <div className="col-7">
            <input type=""  
                   value={formDataStocks.InputStockName}
                   onChange={handleChange} 
                   className="text-dark fw-light w-100 float-start text-start"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputStockName"
               name="InputStockName"
                    class="form-control text-start" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">Current Quantity</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.Inputmax}
                   onChange={handleChange} 
                   className="text-dark fw-light w-100 float-start text-start"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputQty"
               name="InputQty"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 text-nowrap fw-normal font-family-sans-serif">Current Avg. Price Purchased</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.InputAvgPrice}
                   onChange={handleChange} 
                   className="text-dark fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem',color:'#566573'}}
               id="InputAvgPrice"
               name="InputAvgPrice"
                    class="form-control text-end" 
                    disabled/> 
              
            </div>
            </div>

            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 text-nowrap fw-normal font-family-sans-serif">New Purchased Quantity</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.InputNewQty}
                   onChange={handleChange} 
                   className="text-black fw-light w-100 float-start text-end"
                   style={{background:'#ffffff',fontFamily:'Montserrat',fontSize:'0.9rem'}}
               id="InputNewQty"
               name="InputNewQty"
                    class="form-control text-end" 
           /> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 text-nowrap fw-normal font-family-sans-serif">New Purchased Price</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.InputNewAvgPrice}
                   onChange={handleChange} 
                   className="text-black fw-light w-100 float-start text-end"
                   style={{background:'#ffffff',fontFamily:'Montserrat',fontSize:'0.9rem'}}
               id="InputNewAvgPrice"
               name="InputNewAvgPrice"
                    class="form-control text-end" 
           /> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 text-nowrap fw-normal font-family-sans-serif">New Position Quantity</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.InputNewPQty}
                   onChange={handleChange} 
                   className="text-black fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem'}}
               id="InputNewPQty"
               name="InputNewPQty"
                    class="form-control text-end" 
                    readOnly
           /> 
              
            </div>
            </div>
            <div class="form-group row">
            <div className="col-5">
              <p className="text-black fs-6 text-nowrap fw-normal font-family-sans-serif">New Avg. Price</p>
            </div>
            <div className="col-7">
            <input type="number"  
                   value={formDataStocks.InputNewPAvgPrice}
                   onChange={handleChange} 
                   className="text-black fw-light w-100 float-start text-end"
                   style={{background:'rgba(0,0,0,0.08)',borderColor:'transparent',fontFamily:'Montserrat',fontSize:'0.9rem'}}
               id="InputNewPAvgPrice"
               name="InputNewPAvgPrice"
                    class="form-control text-end" 
                    readOnly
           /> 
              
            </div>
            </div>
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">Current Quantity</p>
            </div>
            <div className="col-6">
            <input
                type="number"
                className="form-control text-black bg-white fw-light w-100"
                id="InputQty"
                InputQty                class="form-control text-end" 
                value={formDataStocks.Inputmax}
                onChange={handleChange} disabled
              />
             </div>
            </div> */}
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">Current Avg. Price Purchased</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-20"
                id="InputAvgPrice"
                name="InputAvgPrice"
                class="form-control text-end" 
                value={formDataStocks.InputAvgPrice}
                onChange={handleChange} disabled
              />
            </div>
            </div> */}
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">New Purchased Quantity</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputNewQty"
                name="InputNewQty"
                class="form-control text-end" 
                min="0"
                value={formDataStocks.InputNewQty}
                onChange={handleChange}
              />
            </div>
            </div> */}
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">New Purchased Price</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputNewAvgPrice"
                name="InputNewAvgPrice"
                class="form-control text-end" 
                value={formDataStocks.InputNewAvgPrice}
                onChange={handleChange}
              />
            </div>
            </div> */}
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">New Position Quantity</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputNewPQty"
                name="InputNewPQty"
                disabled
                class="form-control text-end" 
                value={formDataStocks.InputNewPQty}
                         />
            </div>
            </div> */}
            {/* <div class="form-group row">
            <div className="col-6">
              <p className="text-black fs-6 fw-normal font-family-sans-serif">New Avg. Price</p>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control text-black bg-white fw-light w-50"
                id="InputNewPAvgPrice"
                name="InputNewPAvgPrice"
                class="form-control text-end"  disabled
                value={formDataStocks.InputNewPAvgPrice}
                onChange={handleChange}
              />
            </div>
            </div> */}

   
          </div>
          <div className="p-4"></div>
          <div className="col-auto text-end">
          <button type="button" className="btn btn-dark border border-white mr-1" onClick={onClose}>
          Close</button>     

          {!isClicked && (
          <button type="button" className="btn btn-dark border border-white mr-1" onClick={() => handleStockModal(formDataStocks)}>
            Confirm</button> 
        )}
          </div>
          <EditConfirmDialog
        open={editDialogOpen_add}
        onClose={closeEditDialog_add}
        stockData={updateData}
        onUpdate={(updatedStock) => {
          setStocks(prevStocks => prevStocks.map(s => s.ordid === updatedStock.ordid ? updatedStock : s));
        }}
      />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditDialog_add;



// export default Alertmanagementcreate;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import './alertmanagement.css'
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TablePagination from "@mui/material/TablePagination";
import { Icon } from "@mui/material";
import Newalermodal from "./NewAlerModal.js";

const Alertmanagementcreate = ({ initialUserData }) => {
  const username = localStorage.getItem('unm');
  const symbols = localStorage.getItem('symbolstring');
  const [divtra, setDivtra] = useState([]);
  const localLogoImagePath = "/images/companylogos";
  const [updateData, setUpdateData] = useState(null);
  const [newAlertModalOpen, setNewAlertModalOpen] = useState(false);

  let inputString = symbols.replace(/[() ]/g, '');
  let arrayOfStrings = inputString.split(',');
  arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
  let resultString = arrayOfStrings.join(',');

  useEffect(() => {
    const fetchDivData = async () => {
      try {
        const res = await axios.get(Base_URL + "/portfolio/stocks", {
          params: { username: username }
        });
        setDivtra(res.data);
        console.log('---divtra----'+JSON.stringify(res.data))
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    fetchDivData();
  }, [username, resultString]);



  const handleAlertModal = (value) => {
    setUpdateData(value);
    setNewAlertModalOpen(true);
  };

  const handleCloseDialog_add = () => {
    setNewAlertModalOpen(false);
    setUpdateData(null);
  };

  const columns = [
    {
      name: "ctick",
      label: "Stock Ticker Symbol",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const imgSrc = value 
            ? `${localLogoImagePath}/${value}.png` 
            : 'images/companylogos/default-logo.png';
          return (
            <>
              <img
                src={imgSrc}
                width="30px"
                height="auto"
                alt={value}
                style={{ marginRight: '8px' }}
              />
              <span>{value}</span>
            </>
          );
        }
      }
    },
    {
      name: "cname",
      label: "Company",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const imgSrc = value 
            return (
            <>   
              <span>{value}</span>
            </>
          );
        }
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = tableMeta.rowData;
          return (
            <>
              <button onClick={() => handleAlertModal(rowData)}>
             +
              </button>
            </>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    selectableRows:'multiple',
    selectableRowsOnClick: true,
    search: true,
    download: false,
    viewColumns: false,
    print: false,
    responsive: 'scrollFullHeight',
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: 5,
  };

  const getMuiTheme = () =>
    createTheme({
      palette: {
        mode: 'dark',
        backgroundColor: '#000000',
      },
      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              backgroundColor: '#000000',
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              backgroundColor: '#000000',
              color: '#ffffff',
            }
          }
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            actions: {
              display: 'flex',
              flex: 'initial',
              '& > span, & > button': {
                order: 99
              },
              '& > span:last-child, & > button:last-child': {
                order: 1
              },
              '& > span:nth-child(4), & > button:nth-child(4)': {
                order: 2
              }
            }
          }
        },

        MuiTablePagination: {
          styleOverrides: {
            root: {
              display: 'flex',
              alignItems: 'center', // Vertically align items
              justifyContent: 'flex-end', // Adjust this if you want to change the horizontal alignment
              backgroundColor: '#000000',
              color: '#ffffff',
            },
            selectLabel: {
              display: 'flex',
              alignItems: 'center',
            },
            displayedRows: {
              display: 'flex',
              alignItems: 'center',
            },
            selectIcon: {
              color: '#ffffff', // Ensure the icon color matches the text
            },
          },
        },
      }
    });

  return (
    <>
      <div id="component" className="container-fluid py-3 w-50">
        <p className="text-white fw-bold font-family-sans-serif">
          CREATE ALERT
        </p>
        <div className="container-fluid py-3">
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              className="table-dark mytable"
              data={divtra}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </div>
      </div>
      {newAlertModalOpen && (
        <Newalermodal
          open={newAlertModalOpen}
          onClose={handleCloseDialog_add}
          data={updateData}
        />
      )}













      
    </>
  );
};

export default Alertmanagementcreate;

import * as React from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import ImageDisplay from '../../page/ImageDisplayprofile';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { calcDate } from '../calcDate'; 
import moment from 'moment';
function alertmanagementheader() {
  const username = localStorage.getItem('unm');
  const crdate = localStorage.getItem('crdate');
  const today = new Date();
   // Format dates for calcDate function
   const formattedToday = moment(today).format('YYYY-MM-DD');
   const formattedCrDate = moment(crdate).format('YYYY-MM-DD');
 
   // Calculate date difference using calcDate
   const dateDifference = calcDate(formattedToday, formattedCrDate);
  const userid = localStorage.getItem('userid');
       return (
        <>

<div class="container">
<div className="row align-items-start bg-black  text-white centered-text-profilesettings">
<div class="p-5"></div>
<div className="row">
<div className="col-md-3">
    <div className="col" id="CSEMaverick">  
    <Breadcrumbs aria-label="breadcrumb" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700', 
                    // position: 'absolute', 
                    // top: 10, 
                    // left: '1%', 
                    zIndex: 'tooltip', //tooltip:1500 
                }} >
                        <Link underline="hover" color="white" href="/">
            Home</Link>
            <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >Profile</Typography>
        <Typography color="white" sx={{ 
                        fontSize: '0.8rem', 
                    fontWeight: '700'}} >Alert Management</Typography>
      </Breadcrumbs>  
    <table className="table-borderless  bg-black">      <tr>
        <td className="d-flex justify-content-start" colSpan="2"  id="CSEMaverick">CSEMaverick</td></tr>
        <tr>
        <td  colSpan="2"></td>&nbsp;</tr>
       <tr>      
        <td className="d-flex justify-content-start border-0">
        <ImageDisplay imageId={userid} />
        </td>
        <td className="justify-content-start  text-nowrap p-3">
        <div class="row">
        <div class="col font-family-sans-serif text-success-subtle fs-6 fw-normal"><img class="rounded-1 span4 center-block" src="/images/profile/vgeenclock.png"/>Online</div>
      
      </div>
      <div class="row">
        <div class="col font-family-sans-serif text-success-subtle fs-6 fw-normal"><img class="rounded-1 span4 center-block" src="/images/profile/vbag.png"/>Joined {!dateDifference.result ? "today" : dateDifference.result+" ago"}</div>
        <div class="col">&nbsp;</div>
      </div>
        </td>
    </tr>

</table>
</div>
</div>
<div className="col-md-6">
<div className="col text-center">
<div className='p-2'></div>
    <h1 className="text-center">FirstEdge Alert Management</h1>
    <p class="text-center  m-0 px-0 py-0">
      <span class="text-success-subtle fs-6 fw-bold font-family-sans-serif">Set up&nbsp;</span>
      <span class="text-success-subtle fs-6 fw-light font-family-sans-serif">manage alerts&nbsp;</span>
      <span class="text-success-subtle fs-6 fw-bold font-family-sans-serif">for your favorite stocks. Choose your&nbsp;</span>
      <span class="text-success-subtle fs-6 fw-light font-family-sans-serif">preferred media&nbsp;</span>
      <span class="text-success-subtle fs-6 fw-bold font-family-sans-serif">for notifications and&nbsp;</span>
      <span class="text-success-subtle fs-6 fw-light font-family-sans-serif">stay updated with market movements.</span></p>
   </div>
  </div>
  <div className="col-md-3"></div>
    </div>
  
    <div className="col">
   
    </div>
  </div>
  </div>
        
          </>
    );
}

export default alertmanagementheader;
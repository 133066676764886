import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import { Base_URL_TV } from "../../Components/BaseURL_TV.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import { EmailShareButton, TwitterShareButton } from "react-share";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import { usePortfolio } from "../../PortfolioContext";
const useStyles = makeStyles(  
  theme => ({
    centeredHeader: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    centeredContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const SPACED_DATE_FORMAT = "DD MMM YYYY";
const getHyphenatedDate = dateString =>
  moment(dateString, "YYYY-MM-DD").format(SPACED_DATE_FORMAT);

const Dividenttracker = ({ initialUserData }) => {
  const { stocks, currentPrices, currentVolumes } = usePortfolio();
  const username = localStorage.getItem('unm');
  const [fininfor, setFininfor] = useState([]);
  const [divtra, setDivtra] = useState([]);
  const [symbols, setSymbols] = useState([]);
// Utility function to format symbols as a string for API
const getResultString = (symbols) => {
  if (symbols.length === 0) return "";
  return symbols.map((str) => `'${str}'`).join(",");
};
// console.log('--currentPrices---'+JSON.stringify(currentPrices));
// Update symbols whenever stocks change
useEffect(() => {
  if (stocks.length > 0) {
      const fetchedSymbols = stocks.map((stock) => stock.ctick);
      setSymbols(fetchedSymbols);
  }
}, [stocks]);

// Calculate resultString whenever symbols change
const resultString = getResultString(symbols);
useEffect(() => {
//console.log("--resultString---", resultString);
}, [symbols]);



    // if (symbols) {
    //     let inputString = symbols.replace(/[() ]/g, '');
    //     let arrayOfStrings = inputString.split(',');
    //     arrayOfStrings = arrayOfStrings.map(str => `'${str}'`);
    //     resultString = arrayOfStrings.join(',');
    // }

    let loadImage = function(variable) {
      const url_image = `/images/companylogos/${variable}.svg`;

      return (
          <img
              className="rectangle-154 m-1"
              style={{
                  borderRadius: '8px',
                  flexShrink: 0,
                  width: '48px',
                  height: '48px',
                  position: 'relative',
                  objectFit: 'cover',
              }}
              src={url_image}
              alt="Company Logo"
              onError={(e) => { e.target.src = '/images/companylogos/default-logo.png'; }}
          />
      );
  };
  useEffect(() => {
    const fetchDivData = async () => {
      if (!resultString) return; // Exit if resultString is empty
      try {
        const res = await axios.get(Base_URL + "/fininfo/getfininfo", {
          params: { username: username, symbols: resultString }
        });
        setDivtra(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data");
      }
    };

    if (resultString) {
      fetchDivData();
  }
    
  }, [username, resultString]);


  const classes = useStyles();
  const localLogoImagePath = "/images/companylogos/";
  const columns = [
    {
      name: "stock_name",
      label: "Stock",
      options: {
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        setCellProps: () => ({
          className: classes.centeredContent,
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const symbol = divtra[tableMeta.rowIndex].stock_name;
          return (
            <div className={classes.centeredContent}>
              {/* <img
                src={symbol ? `${localLogoImagePath}/${symbol}.png` : 'images/companylogos/default-logo.png'}
                style={{
                  borderRadius: "8px",
                  flexShrink: "0",
                  width: "48px",
                  height: "48px",
                  position: "relative",
                  objectFit: "cover",
                }}
              /> */}
              {loadImage(symbol)}
              <span>{symbol}</span>
            </div>
          );
        }
      }
    },
    {
      name: "possible_next_dividend_date",
      label: "Possible Dividend Dates",
      options: {
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        setCellProps: () => ({
          className: classes.centeredContent,
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (moment(new Date(value)).format(SPACED_DATE_FORMAT));
        }
      }
    },
    {
      name: "dividend_per_share",
      label: "Projected Dividend",
      options: {
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        setCellProps: () => ({
          className: classes.centeredContent,
        }),
        filter: true,
        sort: true,
      }
    },
    {
      name: "payout_ratio",
      label: "Payout Ratio",
      options: {
        setCellHeaderProps: () => ({
          className: classes.centeredHeader,
        }),
        setCellProps: () => ({
          className: classes.centeredContent,
        }),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const symbol = divtra[tableMeta.rowIndex].stock_name;
          const price = currentPrices[symbol];
          if (price) {
            return ((value / price).toFixed(2)+'%'); // Adjust calculation as per your logic
          } else {
            return (value+'%'); // Fallback if price is not available
          }
        }
      }
    },
  ];

  const options = {
    filter: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    responsive: 'standard', // Updated to a supported option
    search: true,
    download: true,
    print: true,
    rowsPerPageOptions: [5, 10, 15],
    rowsPerPage: 5,
  };
  
  const getMuiTheme = () =>
    createTheme({
      palette: {
        mode: 'dark',
        backgroundColor: '#000000',
      },

      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            overflowX: 'none',
            height: 'auto',
            maxHeight: 'auto',
          },
        },
      },


      components: {
        MuiLink: {
          styleOverrides: {
            root: {
              backgroundColor: '#000000',
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              backgroundColor: '#000000',
              color: '#ffffff',
            }
          }
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            actions: {
              display: 'flex',
              flex: 'initial',
              '& > span, & > button': {
                order: 99
              },
              '& > span:last-child, & > button:last-child': {
                order: 1
              },
              '& > span:nth-child(4), & > button:nth-child(4)': {
                order: 2
              }
            }
          }
        }
      }
    });

  return (
    <>
    <div class="container-fluid px-4">
    <div className="row px-4">
      <p className="text-white fs-6 fw-bold font-family-sans-serif">
        DIVIDEND TRACKER
      </p>
      </div>
      <div className="row h-25 w-100 px-2">
      <div className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-3 py-2 " style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable className="table-dark mytable"
            data={divtra}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
     </div>
     </div>
    </>
  );
};

export default Dividenttracker;

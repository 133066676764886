import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Base_URL } from '../../Components/BaseURL.js';
import MUIDataTable from "mui-datatables";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(
  theme => ({
    centeredHeader: {
      '& span': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    centeredContent: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
    },
  })
);
const CSEnews = () => {
      const [csenews, setCseNews] = useState([]);
      const [open, setOpen] = React.useState(false);
      const [data, setData] = useState(null);
      const [responsive, setResponsive] = useState("standard");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const classes = useStyles();
      const handleClickOpen = (value) => {
        setData(value);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }

    useEffect(() => {
        const fetchFiniData = async () => {
            try {
                const username = localStorage.getItem('unm');
                const res = await axios.get(Base_URL + "/news/getnews", {
                    params: { username: username }
                });
                     setCseNews(res.data);
            } catch (error) {
                console.error(error);
                toast.error("Failed to fetch data");
            }
        };

        fetchFiniData();
    }, []);

    const columns = [
      {
        name: "news",
        label: "",
        options: {
            filter: true,
            sort: true,
            setCellHeaderProps: () => ({
              className: classes.centeredHeader,
            }),
            setCellProps: () => ({
              className: classes.centeredContent,
            }),
            customBodyRender: (value) => {
              return (

                <a href="#" onClick={() => openInNewTab(value)}><img className="rounded-1 span4 center-block" width="40px"  src="/images/News.png"/></a>
                 );
        }
      }
    },
        {
            name: "sector",
            label: "Sector",
            options: {
                filter: true,
                sort: true,
                wrap: false,
                setCellHeaderProps: () => ({
                  className: classes.centeredHeader,
                }),
                setCellProps: () => ({
                  className: classes.centeredContent,
                }),
            }
        },
        {
          name: "display_text",
          label: "News",
          options: {
              filter: true,
              sort: true,
              wrap: false,
              setCellHeaderProps: () => ({
                className: classes.centeredHeader,
              }),
              setCellProps: () => ({
                className: classes.centeredContent,
              }),
          }
      },
       ];

    const options = {
      filter: true,
      selectableRows: 'multiple',
      selectableRowsOnClick: true,
      search: true,
      download: false,
      print: false,
      responsive: 'standard',
      tableBodyHeight,
      tableBodyMaxHeight,
      rowsPerPageOptions: [5, 10, 15],
      rowsPerPage: 5,
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    };
     
      const getMuiTheme = () =>
        createTheme({
         palette: {
           mode: 'dark',
           backgroundColor: '#000000',
         },
         components: {
           MuiLink: {
             styleOverrides: {
               root: {
                backgroundColor: '#000000',
               }
             }
           },
           MuiTableCell: {
                    fontcolor:'#ffffff',
                    fontFamily:'Montserrat',
                    fontSize: '14px',
                    styleOverrides:{ root: {
                     backgroundColor: '#000000',
                     fontcolor:'#ffffff'
                    }}
                  },
           MUIDataTableToolbar: {
             styleOverrides: {
               actions: {
                 display: 'flex',
                 flex: 'initial',
                 '& > span, & > button': {
                   order: 99
                 },
                 '& > span:last-child, & > button:last-child': {
                   order: 1
                 },
                 '& > span:nth-child(4), & > button:nth-child(4)': {
                   order: 2
                 }
               }
             }
           }
         }
       });
    return (
        <>
<div className="container-fluid px-4">
<div className="row  px-3">
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
     <DialogTitle id="alert-dialog-title" class>
          {"News"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <div className="px-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
            <p className="text-white fs-6 fw-bold font-family-sans-serif">
                NEWS
            </p>
</div>
{/* <div className="row h-25 w-100">
<div className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 m-0 px-0 py-2 " style={{ backgroundColor: 'black', width: '100%', height: '300px', overflow: 'auto' }}>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable class="table-dark mytable"
                     data={csenews}
                    columns={columns}
                    options={options}
                              />
                   </ThemeProvider>
                
            </div>
            </div> */}
            <div className="row h-25 px-3">
  <div
    className="text-white text-opacity-50 fs-6 fw-normal font-family-sans-serif col-10 "
    style={{
      backgroundColor: 'black',
      width: '100%',
      height: '300px',
      overflowX: 'auto',
      overflowY: 'auto',
    }}
  >
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        className="table-dark mytable"
        data={csenews}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  </div>
</div>

            </div>
          
        </>
    );

};

export default CSEnews;
